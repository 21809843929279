// const role = sessionStorage.getItem("loginType");
const userType = sessionStorage.getItem("loginType");

export default function() {
  if(userType === "Vendor"){
return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""

    },
    {
      title: "Purchase Orders",
      htmlBefore: '<i class="material-icons">inventory</i>',
      to: "/purchase-orders",
    },
    {
      title: "Invoice Status",
      htmlBefore: '<i class="material-icons">description</i>',
      to: "/invoice-status",
    },
    {
      title: "Payment Status",
      htmlBefore: '<i class="material-icons">payment</i>',
      to: "/payment-status",
    }
  ];
  } else if(userType === "Admin"){
return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""

    },
    {
      title: "Employee Master",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/employee-master",
    },
    {
      title: "Branch",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/branch",
    },
    {
      title: "Vendors",
      htmlBefore: '<i class="material-icons">group</i>',
      to: "/vendors",
    },
    {
      title: "Purchase Orders",
      htmlBefore: '<i class="material-icons">inventory</i>',
      to: "/purchase-orders",
    },
    {
      title: "Invoice Status",
      htmlBefore: '<i class="material-icons">description</i>',
      to: "/invoice-status",
    },
    {
      title: "Payment Status",
      htmlBefore: '<i class="material-icons">payment</i>',
      to: "/payment-status",
    }
  ];
  }else{
    return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""

    },
    {
      title: "Approvel Matrix",
      to: "/approvel-matrix",
      htmlBefore: '<i class="material-icons">description</i>',
      htmlAfter: ""

    },
    {
      title: "Invoice Status",
      htmlBefore: '<i class="material-icons">description</i>',
      to: "/invoice-status",
    },
    {
      title: "Payment Status",
      htmlBefore: '<i class="material-icons">payment</i>',
      to: "/payment-status",
    }
  ];
  }


}
