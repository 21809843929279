import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import {
  Card,
  CardBody,
} from "shards-react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const Vendors = () => {
  const { vendorid } = useParams();
  const [vendordata, setVendordata] = useState([]);
  const { id } = useParams();
  console.log(id, "id")

  const [searchval, setSearchval] = useState("");
  // const currentPage = 1;
  useEffect(() => {

    // Calculate the date from 30 days ago
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);

    // Set the state with the calculated date
    setFromDate(thirtyDaysAgo);
  }, []);
  // const currentPage = 1;
  const PageSize = 15;
  const [pageCount, setPageCount] = useState("");
  const [totalemployee, setTotalemployee] = useState("");
  const [fromDate, setFromDate] = useState('');
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [filteredData, setFilteredData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
useEffect(() => {
    fetchbranchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, PageSize]);

  const fetchbranchData = () => {
    let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
      fetch(
        `https://portea.betaphase.in/vendorjson/pages?page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
                console.log("BRANCHPODATA:", res);
                setVendordata(res.data);
                setFilteredData(res.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));

        })
        .catch((error) => {
          console.log("ERROR :", error);

        });
  };

  // useEffect(() => {
  //   setFilteredData(vendordata); // Set initial filtered data when component mounts
  //  }, [vendordata]);

//    useEffect(() => {
//     if (id) {
//         let filterData = vendordata.filter(item =>
//             item.pk_vendor_code.toString().startsWith(id.toString()) ||
//             item.vendor_name.toLowerCase().includes(id.toLowerCase())
//         );

//         setFilteredData(filterData); // Update filtered data state
//     }// Set initial filtered data when component mounts
//    }, [id, vendordata]);


//   const serachData = (e) => {
//     const value = e.target.value;
//     setSearchval(value); // Update search value state

//     if (value) {
//         let filterData = vendordata.filter(item =>
//             item.pk_vendor_code.toString().startsWith(value.toString()) ||
//             item.vendor_name.toLowerCase().includes(value.toLowerCase())
//         );
//         setFilteredData(filterData); // Update filtered data state
//     } else {
//         setFilteredData(vendordata); // Reset filtered data to original state
//     }
// }

useEffect(() => {
        if (id) {
            let filterData = vendordata.filter(item =>
                item.pk_vendor_code.toString().startsWith(id.toString()) ||
                item.vendor_name.toLowerCase().includes(id.toLowerCase())
            );

            setFilteredData(filterData);
        }
    }, [id, vendordata]);

    const searchData = () => {
        if (searchval) {
            let filterData = vendordata.filter(item =>
                item.pk_vendor_code.toString().startsWith(searchval.toString()) ||
                item.vendor_name.toLowerCase().includes(searchval.toLowerCase())
            );
            setFilteredData(filterData);
        } else {
            setFilteredData(vendordata);
        }
    };

const handleClearData = () => {
    setSearchval('');
    setFilteredData(vendordata);
  };

  console.log(filteredData, "filterData")

   // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Vendor Details";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_vendor_code: "Vendor Code",
      vendor_name: "Vendor Name",
      mobile_no: "Mobile No",
      email: "Email",
      status: "Status",
      branch: "Branch",
    },
  ];

  const exportExcel = () => {
    const newVenData = vendordata.map((data) => {
      return {
        pk_vendor_code: data.pk_vendor_code,
        vendor_name: data.vendor_name,
        mobile_no: data.mobile_no,
        email: data.email,
        branch: data.branch[0],
        status: data.status,
      };
    });
    // console.log("VEN-EXCEL:", newVenData);

    const excelStyle = {};

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_vendor_code",
        "vendor_name",
        "mobile_no",
        "email",
        "branch",
        "status",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newVenData, {
      header: [
        "pk_vendor_code",
        "vendor_name",
        "mobile_no",
        "email",
        "branch",
        "status",
      ],
      skipHeader: true,
      origin: 1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END

  return (
  <div>
     <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}


    <Row>
    <Col xs="12" sm="12" md="12">
          <Card className="mt-3">
            <CardBody className="vndr-dta">
              <Row noGutters className="page-header pb-3">
                <PageTitle sm="4" title="Vendors" subtitle="" className="text-sm-left" />
              </Row>
              <Row>
                <Col xs="12" sm="3" md="3">

                   <div className="inf-cnt example">

                    {/* <input type="text" placeholder="Search.." name="search2" onChange={serachData}/>
                    <button type="submit"><i class="fa fa-search"></i></button> */}
                    <input type="text" placeholder="Search.." name="search2" value={searchval} onChange={(e) => setSearchval(e.target.value)} />
                        <button type="submit" onClick={searchData}><i className="fa fa-search"></i></button>
                    </div>

                </Col>

                <Col xs="12" sm="3" md="3" style={{visibility:"hidden"}}>
                  <div className="inf-cnt">
                    <div className="w-100">
                      <select className="form-control search-dash-summary">
                        <option value="0">Status</option>
                        <option value="1">Approved</option>
                        <option value="2">Reject</option>

                      </select>
                    </div>
                  </div>
                </Col>

                <Col xs="12" sm="2" md="2" style={{visibility:"hidden"}}>
                  <div className="inf-cnt mb-4">
                    <div className="w-100">
                      <select className="form-control search-dash-summary">
                        <option value="0">Branch</option>
                        <option value="1">Bangalore</option>
                        <option value="2">Kerala</option>
                        <option value="3">Hyderabad</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="4" md="4">
                  <button type="button" className="pr-order btn btn-outline-primary float-right porder-button" onClick={(e) => exportExcel(vendordata)} style={{marginRight:"0px"}}>
                      Download &nbsp;
                      <span class="material-icons">download</span>
                  </button>
                  <button className="pr-order btn btn-outline-primary porder-button vdr-btn" onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", borderRadius:"0.25rem", marginRight:"15px"}}>
                    <span class="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", marginRight:"5px"}}>clear</span>Clear
                  </button>
                </Col>
              </Row>
              <div className="emp-tble">

                <div className="table-wrapper-scroll-x my-custom-scrollbar vendor-scroll">
                      <table
                        className="table vendor-table"
                        id="vendor-table-data"
                      >
                        <thead className="vendor-head">
                          <tr>
                            <th className="vendor-thead">Vendor Code</th>
                            <th className="vendor-thead">Vendor Name</th>
                            <th className="vendor-thead">R1</th>
                            <th className="vendor-thead">R2</th>
                            <th className="vendor-thead">R3</th>
                            <th className="vendor-thead">Contact Person</th>
                            <th className="vendor-thead">Mobile No</th>
                            {/* <th className="vendor-thead">Branch</th> */}
                            <th className="vendor-thead">Email</th>
                            <th className="vendor-thead">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length !== 0 ? (
                            filteredData.map((item, i) => (
                                <tr key={i}>
                                  <td className="vendor-tbody">
                                    {item?.pk_vendor_code}
                                  </td>
                                  <td className="vendor-tbody">
                                    {item?.vendor_name}
                                  </td>
                                  <td className="vendor-tbody r1">
                                    {item?.pk_owner_1}
                                  </td>
                                  <td className="vendor-tbody r2">
                                    {item?.owner_2}
                                  </td>
                                  <td className="vendor-tbody r3">
                                    {item?.owner_3}
                                  </td>
                                  <td
                                    className="vendor-tbody"
                                    style={{ maxWidth: "100px" }}
                                  >
                                    {item?.contact_name}
                                  </td>
                                  <td className="vendor-tbody">
                                    {item?.mobile_no}
                                  </td>
                                  <td className="vendor-tbody">
                                    {item?.email}
                                  </td>
                                  {item?.status === "Active" ? (
                                    <td className="tab-body vndact">
                                      <span className="dot-ac"></span>
                                      {item?.status}
                                    </td>
                                  ) : (
                                    <td className="tab-body ina">
                                      <span className="dot-in"></span>
                                      {item?.status}
                                    </td>
                                  )}
                                </tr>
                              ))
                          ) : (
                            <span className="no-data-found">
                              ---- No Data Found ----
                            </span>
                          )}
                        </tbody>
                      </table>
                    </div>
              </div>

        </CardBody>

               <div className="vendor-down" style={{marginLeft:"15px", marginRight:"15px"}}>
                  <span className="page-range">
                    <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                    &nbsp; of &nbsp;
                    <p>{totalemployee}</p>
                  </span>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fa fa-angle-double-right" />}
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={<i className="fa fa-angle-double-left" />}
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                  />

                </div>

          </Card>
        </Col>
    </Row>
  </Container>

  </div>
  )
};

export default Vendors;
