import React, { useState, useEffect} from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import PageTitle from "../components/common/PageTitle";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import ReactPaginate from "react-paginate";
import { withRouter, useParams } from 'react-router-dom';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const PurchaseOrders = ({ history }) => {
  const { vendorid } = useParams();
  const [purchasedata, setPurchasedata] = useState([]);
  const [viewdetails, setViewdetails] = useState(true);
  const [viewpurchasedata, setViewpurchasedata] = useState("");
  const [department, setDepartment] = useState({});
  const [branch, setBranch] = useState({});
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState(new Date());
  const [pageDataSize, setPageDataSize] = useState("");
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);
    setFromDate(thirtyDaysAgo);
  }, []);

  const PageSize = 20;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  const handleButtonClick = (purchaseId) => {
    history.push("/purchaseorders-details/" + purchaseId);
  };
  const viewComponent = (e) => {
    setViewdetails(!viewdetails);
    setViewpurchasedata(e);
  };

  const goBacktopurchaseorder = (e) => {
    setViewdetails(!viewdetails);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDated, setSelectedDated] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChanged = (date) => {
    setSelectedDated(date);
  };

  const [searchval, setSearchval] = useState("");
  // console.log("PAGENO", currentPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let userType = sessionStorage.getItem("loginType");
  let vendorCode = sessionStorage.getItem("login-code");

  useEffect(() => {
    fetchbranchData(fromDate, toDate, currentPage, PageSize);
  }, [fromDate, toDate, currentPage, PageSize]);

  const fetchbranchData = (fromDate, toDate, currentPage, PageSize) => {
    console.log("VCODE", vendorid);
    let formatFromDate = moment(new Date(fromDate)).format("YYYY-MM-DD");
    let formatToDate = moment(new Date(toDate)).format("YYYY-MM-DD");
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (userType == "Admin") {

      let url = "";
      if (vendorid) {
        url = `https://portea.betaphase.in/purchase_orderjson/get_selected_item/po_based_on_vendor_code?start_date=${formatFromDate}&end_date=${formatToDate}&search=&page_num=${currentPage}&page_size=${PageSize}&fk_vendor_code=${vendorid}`;
      } else {
        url = `https://portea.betaphase.in/purchase_orderjson/pages?start_date=${formatFromDate}&end_date=${formatToDate}&search=&page_num=${currentPage}&page_size=${PageSize}`;
      }

      fetch(url, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if(vendorid){
            if (res) {
              const data = res;
              const tableData = [...data];
              setPurchasedata(tableData);
              setLoading(false);
              setPageDataSize(data.length);
              setTotalemployee(res.length);
              setPageCount(Math.ceil(res.length / PageSize));
          } else {
            setLoading(false);
          }
          } else {
            if (res.data) {
              const data = res.data;
              const tableData = [...data];
              setPurchasedata(tableData);
              setLoading(false);
              setPageDataSize(res.data.length);
              setTotalemployee(res.total);
              setPageCount(Math.ceil(res.total / PageSize));
          } else {
            setLoading(false);
          }
          }

        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
    } else {
      fetch(
        `https://portea.betaphase.in/purchase_orderjson/get_selected_item/po_based_on_vendor_code?start_date=${formatFromDate}&end_date=${formatToDate}&fk_vendor_code=${vendorCode}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res) {
            const data = res;
            const tableData = [...data];
            setPurchasedata(tableData);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   setFilteredData(purchasedata);
  // }, [purchasedata]);

  // const searchData = (e) => {
  //   const value = e.target.value.toLowerCase();
  //   setSearchval(value);

  //   let filterData = purchasedata.filter(
  //     (data) =>
  //       data.pk_po_DocEntry.toString().startsWith(value) ||
  //       data.po_Docnum.toString().startsWith(value) ||
  //       (typeof data.vendor_name === "string" &&
  //         data.vendor_name.toLowerCase().includes(value))
  //   );

  //   setFilteredData(value ? filterData : purchasedata);
  // };

  useEffect(() => {
    setFilteredData(purchasedata);
  }, [purchasedata]);

  const searchData = () => {
    const filterData = purchasedata.filter(data => {
      const pk_po_DocEntryMatch = data.pk_po_DocEntry.toString().startsWith(searchval.toLowerCase());
      const po_DocnumMatch = data.po_Docnum.toString().startsWith(searchval.toLowerCase());
      const vendor_nameMatch = typeof data.vendor_name.toString() === 'string' && data.vendor_name && data.vendor_name.toString().toLowerCase().includes(searchval.toLowerCase());
      console.log(data.vendor_name.toString())
      return pk_po_DocEntryMatch || po_DocnumMatch || vendor_nameMatch;
    });

    setFilteredData(filterData);
  };

  const handleSearch = () => {
    searchData();

  };

  const [showSearch, setShowSearch] = useState(true);

  const handleClearData = () => {
    setSearchval('')
    setFilteredData(purchasedata);
    setShowSearch(false);
  };
   const exportExcel = () => {
    const newPOData = purchasedata.map((data) => {
      return {
        pk_po_DocEntry: data.pk_po_DocEntry,
        po_Docnum: data.po_Docnum,
        po_date: moment(data.po_date).format("DD/MM/YYYY"),
        po_DueDate: moment(data.po_DueDate).format("DD/MM/YYYY"),
        vendor_ref_no: data.vendor_ref_no,
        branch_name: data.branch_name[0],
        vendor_name: data.vendor_name[0],
        shipto: data.shipto,
        billto: data.billto,
        tax_amount: data.tax_amount,
        doc_total: data.doc_total,
        payment_days: data.payment_days,
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_po_DocEntry",
        "po_Docnum",
        "po_date",
        "po_DueDate",
        "vendor_ref_no",
        "branch_name",
        "vendor_name",
        "shipto",
        "billto",
        "tax_amount",
        "doc_total",
        "payment_days",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newPOData, {
      header: [
        "pk_po_DocEntry",
        "po_Docnum",
        "po_date",
        "po_DueDate",
        "vendor_ref_no",
        "branch_name",
        "vendor_name",
        "shipto",
        "billto",
        "tax_amount",
        "doc_total",
        "payment_days",
      ],
      skipHeader: true,
      origin: -1,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Purchaseorder Details";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_po_DocEntry: "PO Doc.Entry",
      po_Docnum: "PO Doc.No.",
      po_date: "Date",
      po_DueDate: "Due Date",
      vendor_ref_no: "Vendor Ref.No.",
      branch_name: "Branch",
      vendor_name: "Vendor Name",
      shipto: "Ship To",
      billto: "Bill To",
      tax_amount: "Tax Amount",
      doc_total: "Doc. Total",
      payment_days: "Payment Days",
    },
  ];
return (
  <>
  {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "Manager" ||
      userType === "Approver" ||
      userType === "approver" ||
      userType === "FM" ? (
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col xs="12" sm="12" md="12">
                <Card className="mt-3">
                  <CardBody className="pr-order">
                    <Row noGutters className="page-header pb-3">
                      <PageTitle sm="4" title="Purchase Orders" subtitle="" className="text-sm-left" />
                    </Row>
                    <Row>
                      <Col xs="12" sm="3" md="3">
                        <div className="inf-cnt mb-4">
                          <div className="w-100">
                            <div className="dt-cnt">
                              <div className="dt-cnt">
                                <DatePicker
                                selected={fromDate}
                                  className="select from-date"
                                  dateFormat="dd/MM/yyyy"
                                  startDate={fromDate}
                                  endDate={toDate}
                                  onChange={(date) => setFromDate(date)}
                                  maxDate={new Date()}
                                />
                              <i class='fas fa-calendar-alt'></i>
                            </div>

                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" sm="3" md="3">
                        <div className="inf-cnt mb-4">
                          <div className="w-100">
                            <div className="dt-cnt">
                              <div className="dt-cnt">
                                <DatePicker
                                selected={toDate}
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                startDate={fromDate}
                                endDate={toDate}
                                minDate={fromDate}
                                onChange={(date) => setToDate(date)}
                                maxDate={new Date()}
                              />
                              <i class='fas fa-calendar-alt'></i>
                            </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" sm="3" md="3">
                      {/* <div className="inf-cnt example">

                      <input type="text" placeholder="Search.." name="search2" onChange={searchData}/>
                      <button type="submit"><i class="fa fa-search"></i></button>
                      </div> */}

                      <div className="inf-cnt example">
                        <input
                          type="text"
                          placeholder="Search.."
                          name="search2"
                          value={searchval}
                          onChange={(e) => setSearchval(e.target.value)}
                        />
                        <button type="submit" onClick={handleSearch}>
                          <i className="fa fa-search"></i>
                        </button>

                      </div>
                      </Col>

                      <Col xs="12" sm="3" md="3">
                        <button type="button" className="pr-order btn btn-outline-primary float-right porder-button" onClick={(e) => exportExcel(purchasedata)} style={{marginRight:"0px"}}>
                            Download &nbsp;
                            <span class="material-icons">download</span>
                        </button>
                        <button className="pr-order btn btn-outline-primary float-left porder-button" onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", borderRadius:"0.25rem", float:"right", marginRight:"15px"}}>
                          <span className="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", marginRight:"5px"}}>clear</span>Clear
                        </button>
                      </Col>

                    </Row>
                    <div className="prd-dta-tble">
                      <div className="table-wrapper-scroll-x my-custom-scrollbar vendor-scroll">
                        <table
                          className="table vendor-table"
                          id="vendor-table-data"
                        >
                          <thead className="vendor-head">
                          <tr>
                                <th className="po-thead">Po.DocEntry</th>
                                <th className="po-thead">Doc.No</th>
                                <th className="po-thead">Date</th>
                                <th className="po-thead">Vendor Ref.No</th>
                                <th
                                  className="po-thead"
                                  style={{
                                    display:
                                      userType !== "vendor"
                                        ? "table-cell"
                                        : "",
                                  }}
                                >
                                  Vendor Name
                                </th>
                                <th className="po-thead">Ship.To </th>
                                <th className="po-thead">Bill.To </th>
                                <th className="po-thead">
                                  Tax Amount&nbsp;(&#8377;)
                                </th>
                                <th className="po-thead">Doc.Total</th>
                                <th className="po-thead">Remarks</th>
                                <th className="po-thead">Details</th>
                              </tr>
                          </thead>
                          <tbody>
                          {filteredData.length !== 0 ? (
                            filteredData.map((pdata, i) => (
                                    <tr key={i}>
                                      <td className="po-tbody">
                                        <span
                                          className="porder-act"
                                          onClick={(e) =>
                                            viewComponent(pdata?.pk_po_DocEntry)
                                          }
                                        >
                                          {pdata?.pk_po_DocEntry}
                                        </span>
                                      </td>
                                      <td className="po-tbody">
                                        {pdata?.po_Docnum}
                                      </td>
                                      <td className="po-tbody">
                                        {moment(pdata?.po_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td className="po-tbody">
                                        {pdata?.vendor_ref_no}
                                      </td>

                                      <td key={i} className="po-tbody">
                                        {pdata?.vendor_name}
                                      </td>
                                      <td
                                        className="po-tbody"
                                        style={{ maxWidth: "200px" }}
                                      >
                                        {pdata?.shipto}
                                      </td>
                                      <td
                                        className="po-tbody"
                                        style={{ maxWidth: "200px" }}
                                      >
                                        {pdata?.billto}
                                      </td>
                                      <td className="po-tbody">
                                        {pdata?.tax_amount}
                                      </td>
                                      <td className="po-tbody">
                                        {pdata?.doc_total}
                                      </td>
                                      <td
                                        className="po-tbody"
                                        style={{ maxWidth: "200px" }}
                                      >
                                        {pdata?.remarks}
                                      </td>
                                      <td className="po-tbody">
                                          <button
                                              className="btn-secondary porder-act"
                                              onClick={(e) => handleButtonClick(pdata?.pk_po_DocEntry)}

                                            >
                                              <i className="bi bi-eye-fill" /> View
                                            </button>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <>
                                {filteredData.length !== 0 ? (
                                    filteredData.map((pvdata, i) => (
                                        <tr>
                                          <td className="po-tbody">
                                            <span
                                              className="porder-act"
                                              onClick={(e) =>
                                                viewComponent(
                                                  pvdata?.pk_po_DocEntry
                                                )
                                              }
                                            >
                                              {pvdata?.pk_po_DocEntry}
                                            </span>
                                          </td>
                                          <td className="po-tbody">
                                            {pvdata?.po_Docnum}
                                          </td>
                                          <td className="po-tbody">
                                            {moment(pvdata?.po_date).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                          <td className="po-tbody">
                                            {pvdata?.vendor_ref_no}
                                          </td>

                                          <td
                                            className="po-tbody"
                                            style={{
                                              display:
                                                userType === "vendor" ||
                                                userType === "Vendor"
                                                  ? "none"
                                                  : "",
                                            }}
                                          >
                                            {pvdata?.vendor_name}
                                          </td>
                                          <td
                                            className="po-tbody"
                                            style={{ maxWidth: "200px" }}
                                          >
                                            {pvdata?.shipto}
                                          </td>
                                          <td
                                            className="po-tbody"
                                            style={{ maxWidth: "200px" }}
                                          >
                                            {pvdata?.billto}
                                          </td>
                                          <td className="po-tbody">
                                            {pvdata?.tax_amount}
                                          </td>
                                          <td className="po-tbody">
                                            {pvdata?.doc_total}
                                          </td>
                                          <td
                                            className="po-tbody"
                                            style={{ maxWidth: "200px" }}
                                          >
                                            {pvdata?.remarks}
                                          </td>
                                          <td className="po-tbody">
                                            <button
                                              className="btn-secondary porder-act"
                                              onClick={handleButtonClick}
                                            >
                                              <i className="bi bi-eye-fill" /> View
                                            </button>
                                          </td>
                                        </tr>
                                      ))
                                  ) : (
                                    <span className="no-data-found">
                                      ---- No Data Found ----
                                    </span>
                                  )}
                                </>
                              )}
                            </tbody>
                        </table>
                      </div>
                    </div>
              </CardBody>

              <div className="porder-down d-flex">
                          <span className="page-range">
                            <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                            &nbsp; of &nbsp;
                            <p>{totalemployee}</p>
                          </span>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-double-right" />}
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel={<i className="fa fa-angle-double-left" />}
                            renderOnZeroPageCount={null}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                          />
              </div>
                </Card>
          </Col>
        </Row>
      </Container>
      ) : (
        < >
        </>
      )}
  </>
);

          }

export default withRouter(PurchaseOrders);;
