import React, { useState, useEffect, useRef} from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import moment from "moment";
import PageTitle from "./components/common/PageTitle";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import { useParams } from "react-router-dom";
// import './assets/css/my2.css';

const Purchaseordersdetails = (props) => {


   const handleAlertClose = () => setModelShow(false);

  const alertClosesuto = () => {
    setTimeout(() => setModelShow(false), 2000);
  };
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

   const userType = sessionStorage.getItem("loginType");
  const { id } = useParams();
  let purchaseId = id;
  const [purchaseorderdata, setPurchaseorderdata] = useState([]);
  const [purchaseorderitems, setPurchaseorderitems] = useState([]);
  const [editingQnty, setEditingQnty] = useState([]);
   const [qtyAlertMsg, setQtyAlertMsg] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   const [responseStatus, setResponseStatus] = useState("");
    const [modelShow, setModelShow] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("");
  const [progress, setProgress] = useState("");
  const [file, setFile] = useState("");
  useEffect(() => {
    fetchpurchaseiddata();
    fetchpurchaseorderlist();
    let urlId = window.location.pathname
    console.log(id, "urlId")
  }, [purchaseId]);

  const fetchpurchaseiddata = () => {
    let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
    fetch(
      `https://portea.betaphase.in/purchase_orderjson/get_selected_item?pk_po_DocEntry=${purchaseId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("DATAVIEW-----:", res);
        const dataObject = res[0];
        setPurchaseorderdata(dataObject);
      })
      .catch((error) => console.error("ERROR:", error));
  };

   const fetchpurchaseorderlist = () => {
       let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
    fetch(
      `https://portea.betaphase.in/po_line_itemsjson/get_selected_item?fk_po_DocEntry=${purchaseId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("POITEMS:", res.data);
        setPurchaseorderitems(res.data);
      })
      .catch((error) => console.error("ERROR:", error));
  };
  let navItem;

  const handleBackClick = () => {
  // Navigate back to the previous page
  window.history.back();

  // Add event listener to execute the styling changes after the navigation

};

   const [lineNumber, setLineNumber] = useState([]);
  const setLineItemData = (e, index) => {
    if (e.target.checked) {
      setLineNumber([...lineNumber, e.target.value]);
      setEditingQnty([
        ...editingQnty,
        {
          lineNo: e.target.value,
          qtyValue: e.target.selected,
        },
      ]);
    } else {
      const list = [...editingQnty];
      list.splice(index, 1);
      setEditingQnty(list);
      setLineNumber(lineNumber.filter((value) => value !== e.target.value));
    }
  };

  const setQuantity = (e, index) => {
    if (e.target.value <= e.target.selected) {
      const { name, value } = e.target;
      const list = [...editingQnty];
      list[index][name] = value;
      editingQnty[index].lineNo = e.target.id;
      setEditingQnty(list);
      setQtyAlertMsg({ status: false, message: "" });
    } else {
      const { name, value } = e.target;
      const list = [...editingQnty];
      list[index][name] = e.target.selected;
      e.target.value = "";
      editingQnty[index].lineNo = e.target.id;
      setEditingQnty(list);
      setQtyAlertMsg({
        status: true,
        id: e.target.id,
        message: "Enter Valid Quantity",
      });
    }
  };
  console.log("editingQnty", editingQnty);

  useEffect(() => {
    let selectItemArray = [];
    for (var i = 0; i < purchaseorderitems.length; i++) {
      for (var j = 0; j < lineNumber.length; j++) {
        for (var k = 0; k < editingQnty.length; k++) {
          if (
            purchaseorderitems[i].LineNum === lineNumber[j] &&
            editingQnty[k].lineNo === lineNumber[j]
          ) {
            purchaseorderitems[i].selectStatus = "true";
            selectItemArray.push({
              id: purchaseorderitems[i].LineNum,
              ItemCode: purchaseorderitems[i].ItemCode,
              Quantity: purchaseorderitems[i].Qty,
              fulfilled: JSON.parse(editingQnty[k].qtyValue),
              remaining_qty:purchaseorderitems[i].Qty - editingQnty[k].qtyValue,
              Price: purchaseorderitems[i].UnitRate,
              Currency: purchaseorderitems[i].Currency,
              DiscountPercent: purchaseorderitems[i].Discount,
              WarehouseCode: purchaseorderitems[i].fk_whs_code,
              UoMCode: purchaseorderitems[i].UOM,
              BaseEntry: purchaseorderitems[i].fk_po_DocEntry,
              BaseLine: purchaseorderitems[i].LineNum,
              BaseType: "22",
            });
          }
        }
      }
    }
    setSelectedItem(selectItemArray);
  }, [lineNumber, purchaseorderitems, editingQnty]);

  const [selectedItem, setSelectedItem] = useState([]);
   console.log("Selected Items::", selectedItem);
  let isValidate = lineNumber.length !== 0 && file !== "";

//   const UploadInvoiceforApproval = (e) => {
//   const invsdata = {
//     CardCode: purchaseorderdata.fk_vendor_code,
//     DocDate: purchaseorderdata.po_date,
//     DocDueDate: purchaseorderdata.po_DueDate,
//     DocObjectCode: "oPurchaseInvoices",
//     DocType: "dDocument_Items",
//     ShipToCode: purchaseorderdata.shipto,
//     PayToCode: purchaseorderdata.billto,
//     BPL_IDAssignedToInvoice: "1",
//     NumAtCard: purchaseorderdata.vendor_ref_no,
//     U_IKVHDID: "test",
//     Attachment: file, // Make sure file contains the correct data
//     dept_status: "Pending",
//     inv_status: "Pending",
//     DocumentLines: selectedItem,
//   };
//   console.log("DRFT-DATA:", invsdata);

//   let requestOption = {
//     method: "POST",
//     mode: "cors",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json",
//     },
//     body: JSON.stringify(invsdata),
//   };

//   fetch(`https://portea.betaphase.in/invoice_savejson/items/`, requestOption)
//     .then((res) => res.json())
//     .then((res) => {
//       console.log("SAVEINVOICE:", res);
//       if (res?.status === 100) {
//         setResponseStatus(res.status);
//         setModelShow(!modelShow);
//         setSubmitSuccessMessage("Created");
//         alertClosesuto();
//         setFile("");
//         setProgress("");
//       } else if (res?.status === "Failed") {
//         setModelShow(!modelShow);
//         setSubmitMessage(res.errorMessage);
//         alertClosesuto();
//         setFile("");
//         setProgress("");
//       }
//     })
//     .catch((error) => console.error("ERROR:", error));
// };

  const UploadInvoiceforApproval = () => {
    const invsdata = {
      CardCode: purchaseorderdata.fk_vendor_code,
      DocDate: purchaseorderdata.po_date,
      DocDueDate: purchaseorderdata.po_DueDate,
      DocObjectCode: "oPurchaseInvoices",
      DocType: "dDocument_Items",
      ShipToCode: purchaseorderdata.shipto,
      PayToCode: purchaseorderdata.billto,
      BPL_IDAssignedToInvoice: "1",
      NumAtCard: purchaseorderdata.vendor_ref_no,
      U_IKVHDID: "test",
      Attachment: file, // Make sure file contains the correct data
      dept_status: "Pending",
      inv_status: "Pending",
      DocumentLines: selectedItem,
    };

    console.log("DRFT-DATA:", invsdata);

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(invsdata),
    };

    fetch(`https://portea.betaphase.in/invoice_savejson/items/`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        console.log("SAVEINVOICE:", res);
        if (res?.status === 100) {
          setResponseStatus(res.status);
          setModelShow(!modelShow);
          setSubmitSuccessMessage("Created");
          alertClosesuto();
          setFile("");
          setProgress("");
        } else if (res?.status === "Failed") {
          setModelShow(!modelShow);
          setSubmitMessage(res.errorMessage);
          alertClosesuto();
          setFile("");
          setProgress("");
        }
      })
      .catch((error) => console.error("ERROR:", error));
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
        setProgress(Math.round((100 * 50) / 50));
      }
    });
  };

return (
  <Container fluid className="main-content-container px-4">
    <Row>
      <Col xs="12" sm="12" md="12">
            <Card className="mt-4 mb-4">
              <CardBody className="pr-dtls">
                <Row noGutters className="page-header py-4">
                  <Col xs="12" sm="12" md="6">
                    <div className="d-flex align-item-center pr-lft-cnt">
                    <i className="material-icons">chevron_left</i>
                    <h5 className="pgtle" onClick={handleBackClick} style={{paddingLeft:"0px", fontSize:"18px !important;"}}>Purchase Order List</h5>
                    </div>
                  </Col>
                </Row>
                {!props.viewdetails ? (
                  <div className={`page-wrapper ${props.pagewrap?.style}`}>
                    <div className="pr-ord-dtls">
                      <div className="row view-details">
                        <div className="col-md-12">
                          <div className="card view-card-body">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Po.DocEntry</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.pk_po_DocEntry}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6 right-details">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">

                                    </div>
                                    <div className="col-6">

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Doc.Noc</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.po_Docnum}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 right-details">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Tax Amount(&#8377;)</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.tax_amount}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Date</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {purchaseorderdata?.po_date && moment(purchaseorderdata?.po_date).format("DD/MM/YYYY")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 right-details">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Doc.Total</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.doc_total}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Vendor.Ref.No</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.vendor_ref_no}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 right-details">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Branch</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.branch_name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 right-details"></div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Ship.To</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.shipto}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 right-details">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">Bill.To</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;{purchaseorderdata?.billto}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row viewpage" style={{marginTop:"30px"}}>
                        <div className="col-lg-12">
                          <div className="prd-tble">
                            <table className="table employee-table">
                              <thead className="views-thead">
                                      <tr>
                                        <th className="view-thead">Line No.</th>
                                        <th className="view-thead">Item Name</th>
                                        <th className="view-thead">Quantity</th>
                                        <th className="view-thead">Discount %</th>
                                        <th className="view-thead">Unit Rate</th>
                                        <th className="view-thead">Line Total</th>
                                        <th className="view-thead">Tax Code</th>
                                        <th className="view-thead">Tax%</th>
                                        <th className="view-thead">Tax Amount</th>
                                        <th className="view-thead">Gross Total</th>
                                        <th className="view-thead">UOM</th>
                                        <th className="view-thead">Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {purchaseorderitems !== "" ||
                                      purchaseorderitems !== null
                                        ? purchaseorderitems.map((item, index) => (
                                            <tr key={index}>
                                              <td className="view-tbody">
                                                {userType === "Vendor" ||
                                                userType === "vendor" ? (
                                                  <input className="pr-dtls-chk"
                                                    type="checkbox"
                                                    id="invoice-lineitem-checkbox"
                                                    selected={item?.Qty}
                                                    name={item?.ItemCode}
                                                    disabled={
                                                      item?.status === "created" ||
                                                      item?.status === "Created"
                                                        ? true
                                                        : false
                                                    }
                                                    value={item?.LineNum}
                                                    onClick={(e) => {
                                                      setLineItemData(e, index);
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                                {item?.LineNum}
                                              </td>
                                              <td
                                                className="view-tbody"
                                                style={{ maxWidth: "100px" }}
                                              >
                                                {item?.ItemName}
                                              </td>
                                              {item?.selectStatus ? (
                                                <td className="view-tbody">
                                                  {item?.selectStatus === "true" && (
                                                    <>
                                                      <input
                                                        type="text"
                                                        className="input-qty-edit"
                                                        placeholder={item?.Qty}
                                                        selected={item?.Qty}
                                                        name="qtyValue"
                                                        id={item?.LineNum}
                                                        onChange={(e) =>
                                                          setQuantity(e, index)
                                                        }
                                                      />

                                                      <div className="wrong-qty-msg">
                                                        {item?.LineNum == qtyAlertMsg.id &&
                                                        qtyAlertMsg.status
                                                          ? qtyAlertMsg.message
                                                          : ""}
                                                      </div>
                                                    </>
                                                  )}
                                                </td>
                                              ) : (
                                                <td className="view-tbody">{item?.Qty}</td>
                                              )}
                                              <td className="view-tbody">
                                                {item?.Discount}%
                                              </td>
                                              <td className="view-tbody">{item?.UnitRate}</td>
                                              <td className="view-tbody">
                                                {item?.Linetotal}
                                              </td>
                                              <td className="view-tbody">{item?.TaxCode}</td>
                                              <td className="view-tbody">
                                                <b>{item?.Tax}%</b>
                                              </td>
                                              <td className="view-tbody">
                                                <b>{item?.TaxAmount}</b>
                                              </td>
                                              <td className="view-tbody">
                                                <b>{item?.GrossTotal}</b>
                                              </td>
                                              <td className="view-tbody">
                                                <b>{item?.UOM}</b>
                                              </td>
                                              <td className="view-tbody">
                                                {item?.po_status}
                                              </td>
                                            </tr>
                                          ))
                                        : "NO DATA FOUND"}
                                    </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="pro-bar-div">
                    {progress && (
                    <ProgressBar
                      now={progress}
                      label={"File Uploaded"}
                      className="pro-bar"
                    />
                  )}
                </div>
                {userType === "Vendor" || userType === "vendor" ? (
                  <div className="invoice-save-btn d-flex justify-content-end">
                    <div className="d-flex up-btn">
                      <button
                        type="button"
                        className="pr-order btn btn-outline-primary float-right porder-button"
                        onClick={uploadClick} style={{marginTop:"10px"}}
                      >
                        <i className="fa fa-upload" /> Upload PDF
                      </button>
                      <input
                        id="getFile"
                        type="file"
                        name="filetobase64"
                        accept="application/pdf"
                        className="btn-primary upload"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />

                    </div>

                    <span className="view inv-upload-btn">
                      <button
                        disabled={!isValidate}
                        id="view-invoice"
                        type="button"
                        className="pr-order btn btn-outline-primary porder-button"
                        onClick={(e) => UploadInvoiceforApproval(e)} style={{marginTop:"10px"}}
                      >
                        Save Invoice &nbsp;&nbsp;
                        <i className="fa fa-upload"></i>
                      </button>
                    </span>
                  </div>
                ) : (
                  ""
                )}
                    </div>
                  </div>
                 ) : null}


          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);
};

export default Purchaseordersdetails;
