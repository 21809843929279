import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import PageTitle from "../components/common/PageTitle";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import moment from "moment";
import ReactPaginate from "react-paginate";
import { withRouter } from 'react-router-dom';
import { useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const PaymentStatus = ({ history }) => {


  const [paymentdata, setPaymentdata] = useState([]);
  // const [startDate, setStartDate] = useState();
  const [searchval, setSearchval] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [status, setStatus] = useState({});
  const [viewdetails, setViewdetails] = useState(true);
  const [viewpaymentdata, setViewpaymentdata] = useState("");
  const [presentDate, setPresentDate] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const [filteredData, setFilteredData] = useState([])

  const viewComponent = (e) => {
    setViewdetails(!viewdetails);

  };
  const goBacktopurchaseorder = (e) => {
    setViewdetails(!viewdetails);
  };

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });
  const handleButtonClick = (paymentId) => {
    // Navigate to the "purchase-orders.js" file or route
    // navigate('/purchase-orders');
    history.push('/paymentstatus-details/' + paymentId);
  };

  useEffect(() => {

    // Calculate the date from 30 days ago
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);

    // Set the state with the calculated date
    setFromDate(thirtyDaysAgo);
  }, []);

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  // console.log("PAGENO", currentPage);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  var userType = sessionStorage.getItem("loginType");

  const { vendorid } = useParams();

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  var loginCode = sessionStorage.getItem("login-code");
  var userType = sessionStorage.getItem("loginType");



  useEffect(() => {
    fetchpaymentData(fromDate, toDate, currentPage, PageSize);
  }, [fromDate, toDate, currentPage, PageSize]);

  const fetchpaymentData = (fromDate, toDate, currentPage, PageSize) => {
    let formatFromDate = moment(new Date(fromDate)).format("YYYY-MM-DD")
    let formatToDate = moment(new Date(toDate)).format("YYYY-MM-DD")
    let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
      if(userType == "Admin"){
        let url = "";
        if (vendorid) {
           url =`https://portea.betaphase.in/payment_statusjson/get_selected_item/payment_status_based_on_vendor_code?start_date=${formatFromDate}&end_date=${formatToDate}&fk_vendor_code=${vendorid}`;
        } else {

          url =`https://portea.betaphase.in/payment_statusjson/pages?start_date=${formatFromDate}&end_date=${formatToDate}&search=&page_num=${currentPage}&page_size=${PageSize}`;

        }
        fetch(url, requestOption)
          .then((res) => res.json())
          .then((res) => {
                   if(vendorid){
            if (res) {
              const data = res;
              const tableData = [...data];
              setPaymentdata(tableData);
              setLoading(false);
              setPageDataSize(data.length);
              setTotalemployee(res.length);
              setPageCount(Math.ceil(res.length / PageSize));
          } else {
            setLoading(false);
          }
          } else {
            if (res.data) {
              const data = res.data;
              const tableData = [...data];
              setPaymentdata(tableData);
              setLoading(false);
              setPageDataSize(res.data.length);
              setTotalemployee(res.total);
              setPageCount(Math.ceil(res.total / PageSize));
          } else {
            setLoading(false);
          }
          }
          })
          .catch((error) => {
            console.log("ERROR :", error);
            setError(error.message || 'An error occurred');
        setLoading(false);
          });
      }else if(userType == "Vendor"){

        fetch(
          `https://portea.betaphase.in/payment_statusjson/get_selected_item/payment_status_based_on_vendor_code?start_date=${formatFromDate}&end_date=${formatToDate}&fk_vendor_code=${loginCode}`,
        requestOption
      )

      .then((res) => res.json())
        .then((res) => {
                if (res) {
            const data = res;
            const tableData = [...data];
            setPaymentdata(tableData);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
       }else if(userType == "Approver"){

        fetch(
          `https://portea.betaphase.in/payment_statusjson_matching_owners/from_vendor_table/?start_date=${formatFromDate}&end_date=${formatToDate}&emp_code=${loginCode}`,
        requestOption
      )

      .then((res) => res.json())
        .then((res) => {
                if (res) {

            setPaymentdata(res.data);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
       }


  };

  // useEffect(() => {
  //   setFilteredData(paymentdata);
  // }, [paymentdata]);

  // const searchData = (e) => {
  //   const value = e.target.value.toLowerCase(); // Convert search value to lowercase
  //   setSearchval(value); // Update search value state

  //   let filterData = paymentdata.filter(item =>
  //     item.pk_payment_num.toString().startsWith(value) ||
  //     (typeof item.vendor_name === 'string' && item.vendor_name.toLowerCase().includes(value))
  //   );

  //   setFilteredData(value ? filterData : paymentdata); // Update filtered data state based on search value
  // };

  // useEffect(() => {
  //   setFilteredData(paymentdata);
  // }, [paymentdata]);

  // const searchData = () => {
  //   const value = searchval.toLowerCase(); // Convert search value to lowercase

  //   let filterData = paymentdata.filter(item =>
  //     item.pk_payment_num.toString().startsWith(value) ||
  //     (typeof item.vendor_name.toString() === 'string' && item.vendor_name.toLowerCase().includes(value))

  //   );

  //   setFilteredData(value ? filterData : paymentdata); // Update filtered data state based on search value
  // };

  // const handleInputChange = (e) => {
  //   setSearchval(e.target.value); // Update search value state
  // };

  useEffect(() => {
    setFilteredData(paymentdata);
  }, [paymentdata]);

  const searchData = () => {
    const filterData = paymentdata.filter(item => {
      const pk_payment_numMatch = item.pk_payment_num.toString().startsWith(searchval.toLowerCase());
      const vendor_nameMatch = typeof item.vendor_name.toString() === 'string' && item.vendor_name && item.vendor_name.toString().toLowerCase().includes(searchval.toLowerCase());
      console.log(item.vendor_name.toString())
      return pk_payment_numMatch || vendor_nameMatch;
    });

    setFilteredData(filterData);
  };

  const handleSearch = () => {
    searchData();

  };

  // const handleButtonClick = () => {
  //   searchData(); // Trigger search logic
  //   setButtonClicked(true); // Set button clicked to true
  // };


  const handleClearData = () => {
    setSearchval('')
    setFilteredData(paymentdata);
  };

  // DOWNLOAD EXCEL-FORMATE START

  const fileName = "Payment Status";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_payment_num: "Payment No.",
      fk_branch: "Branch",
      payment_amount: "Amount",
      payment_date: "Payment Date",
      payment_ref: "Payment Reference",
      vendor_name: "Vendor Name",
      narration: "Narration",
      status: "Status",
    },
  ];

  const exportExcel = () => {
    const newInvoiceData = paymentdata.map((data) => {
      return {
        pk_payment_num: data.pk_payment_num,
        fk_branch: data.fk_branch,
        payment_amount: data.payment_amount,
        payment_date: moment(data.payment_date).format("DD/MM/YYYY"),
        payment_ref: data.payment_ref,
        vendor_name: data.vendor_name[0],
        narration: data.narration,
        status: data.status,
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_payment_num",
        "fk_branch",
        "payment_amount",
        "payment_date",
        "payment_ref",
        "vendor_name",
        "narration",
        "status",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newInvoiceData, {
      header: [
        "pk_payment_num",
        "fk_branch",
        "payment_amount",
        "payment_date",
        "payment_ref",
        "vendor_name",
        "narration",
        "status",
      ],
      skipHeader: true,
      origin: -1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // DOWNLOAD EXCEL-FORMATE END

return (
  <div>
  {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "Approver" ||
      userType === "approver" ? (
  <Container fluid className="main-content-container px-4 pb-4">
    <Row>
    <Col xs="12" sm="12" md="12">
          <Card className="mt-3">
            <CardBody className="pr-order">
              <Row noGutters className="page-header pb-3">
                <PageTitle title="Payment Status" subtitle="" md="4" className="" />
              </Row>
              <Row>
              <Col xs="12" sm="3" md="3">
                      <div className="inf-cnt mb-4">
                        <div className="w-100">
                          <div className="dt-cnt">
                             <div className="dt-cnt">
                              <DatePicker
                              selected={fromDate}
                                // selectsStart
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setFromDate(date)}
                                maxDate={new Date()}

                              />
                            <i class='fas fa-calendar-alt'></i>
                          </div>

                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" sm="3" md="3">
                      <div className="inf-cnt mb-4">
                        <div className="w-100">
                          <div className="dt-cnt">
                             <div className="dt-cnt">
                              <DatePicker
                              selected={toDate}
                              // selectsEnd
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              minDate={fromDate}
                              onChange={(date) => setToDate(date)}
                              maxDate={new Date()}
                            />
                            <i class='fas fa-calendar-alt'></i>
                          </div>


                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xs="12" sm="3" md="3">

                      <div className="inf-cnt example">
                        {/* <input type="text" placeholder="Search.." name="search2" value={searchval} onChange={handleInputChange}/>
                        <button type="button" onClick={searchData}><i className="fa fa-search"></i></button> */}
                         <input
                          type="text"
                          placeholder="Search.."
                          name="search2"
                          value={searchval}
                          onChange={(e) => setSearchval(e.target.value)}
                        />
                        <button type="submit" onClick={handleSearch}>
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </Col>
                    <Col xs="12" sm="3" md="3">
                      <button type="button" className="pr-order btn btn-outline-primary float-right porder-button" onClick={(e) => exportExcel(paymentdata)} style={{marginRight:"0px"}}>
                        Download &nbsp;
                        <span class="material-icons">download</span>
                      </button>
                      <button className="pr-order btn btn-outline-primary float-left porder-button" onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", borderRadius:"0.25rem"}}>
                        <span class="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", marginRight:"5px"}}>clear</span>Clear
                      </button>
                    </Col>
              </Row>
              <div className="emp-tble pts-tble">
              <div className="table-wrapper-scroll-x my-custom-scrollbar paymentsta-scroll">
                        <table
                          className="table payment-table"
                          id="payment-status-data"
                        >
                          <thead className="payment-head">
                            <tr>
                              <th className="pay-thead">Payment.No</th>
                              <th className="pay-thead">Date</th>
                              {/* <th className="pay-thead">Vendor Ref.No</th> */}

                              {userType === "vendor" ||
                              userType === "Vendor" ? (
                                ""
                              ) : (
                                <th className="pay-thead">Vendor Name</th>
                              )}
                              {/* <th className="pay-thead">Branch</th> */}
                              <th className="pay-thead">Amount</th>
                              <th className="pay-thead">Naration</th>
                              <th className="pay-thead sta">Status</th>
                              <th className="pay-thead">Action</th>
                            </tr>
                          </thead>
                          <tbody>

                          {filteredData.length !== 0 ? (
                          filteredData.map((item, i) => (
                                  <tr key={i}>
                                    <td className="pay-tbody">
                                      <span
                                        className="pay-act"
                                        onClick={() =>
                                          viewComponent(item?.pk_payment_num)
                                        }
                                      >
                                        {item?.pk_payment_num}
                                      </span>
                                    </td>
                                    <td className="pay-tbody">
                                      {moment(item?.payment_date).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </td>
                                    {/* <td className="pay-tbody">
                                      {item?.vendor_ref_no}
                                    </td> */}
                                    {userType === "vendor" ||
                                    userType === "Vendor" ? (
                                      ""
                                    ) : (
                                      <td className="pay-tbody">
                                        {item?.vendor_name}
                                      </td>
                                    )}
                                    {/* <td className="pay-tbody">
                                      {item?.fk_branch}
                                    </td> */}
                                    <td className="pay-tbody">
                                      {item?.payment_amount}
                                    </td>
                                    <td
                                      className="pay-tbody"
                                      style={{ maxWidth: "200px" }}
                                    >
                                      {item?.narration}
                                    </td>
                                    <td className="pay-tbody sta">
                                      {item?.status === "paid" ? (
                                        <span className="pay-paid">Paid</span>
                                      ) : (
                                        <span className="pay-pen">Pending</span>
                                      )}
                                    </td>
                                     <td className="po-tbody">
                                        <button
                                            className="btn-secondary porder-act"
                                            onClick={(e) => handleButtonClick(item?.pk_payment_num)}
                                          >
                                            <i className="bi bi-eye-fill" /> View
                                          </button>
                                    </td>
                                  </tr>
                                ))
                            ) : (
                              <>
                                {filteredData.length !== 0 ? (
                                  filteredData.map((item, i) => (
                                    <tr key={i}>
                                      <td className="pay-tbody">
                                        <span className="pay-act">
                                          {item?.pk_payment_num}
                                        </span>
                                      </td>
                                      <td className="pay-tbody">
                                        {moment(item?.payment_date).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td className="pay-tbody">
                                        {item?.payment_ref}
                                      </td>
                                      {userType !== "vendor" ||
                                      userType !== "Vendor" ? (
                                        <td className="pay-tbody">
                                          {item?.vendor_name}
                                        </td>
                                      ) : (
                                        ""
                                      )}
                                      {/* <td className="pay-tbody">
                                        {item.fk_branch}
                                      </td> */}
                                      <td className="pay-tbody">
                                        {item?.payment_amount}
                                      </td>
                                      <td className="pay-tbody">
                                        {item?.narration}
                                      </td>
                                      <td className="pay-tbody sta">
                                        {item?.status === "paid" ? (
                                          <span className="pay-paid">Paid</span>
                                        ) : (
                                          <span className="pay-pen">
                                            Pending
                                          </span>
                                        )}
                                      </td>
                                       <td className="po-tbody">
                                        <button
                                            className="btn-secondary porder-act"
                                            onClick={(e) => handleButtonClick(item?.pk_payment_num)}
                                          >
                                            <i className="bi bi-eye-fill" /> View
                                          </button>
                                    </td>
                                    </tr>
                                  ))
                                ) : (
                                  <span className="no-data-found">
                                    ---- No Data Found ----
                                  </span>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
              </div>

        </CardBody>



          <div className="porder-down d-flex">
                        <span className="page-range">
                          <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                          &nbsp; of &nbsp;
                          <p>{totalemployee}</p>
                        </span>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={<i className="fa fa-angle-double-left" />}
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />

            </div>

          </Card>
        </Col>
    </Row>

  </Container>
       ):(
        <>
        </>
      )}
      </div>
);

};
export default withRouter(PaymentStatus);
