
import React from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import SmallStats from "./components/common/SmallStats";
// import userimage from "./../images/user.png";
// import poorder from "./../images/po-order.png";
// import invoice from "./../images/invoice-download.png";
// import payment from "./../images/payment-paid.png";
import "./assets/css/my.css";
import poimg from "./images/po.png";
import PageTitle from "./components/common/PageTitle";
import PropTypes from "prop-types";



const Vendorlogindetails = ({ smallStats }) => (

  <Container fluid className="main-content-container px-4">

    {/* <Row>
      <Col xs="12" sm="6" md="3">
        <Card>
          <CardBody>
            <div className="d-flex usr-info">
              <div>
                 <img
                id="main-logo"
                className=" usr-img d-inline-block align-top mr-2"
                style={{ maxWidth: "100px" }}
                src={userimage}
                alt="user"
              />
              </div>
              <div>
                <span>44</span>
                <p>Active Vendors</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xs="12" sm="6" md="3">
        <Card>
          <CardBody>
            <div className="d-flex usr-info">
              <div>
                 <img
                id="main-logo"
                className="usr-po d-inline-block align-top mr-2"
                style={{ maxWidth: "100px" }}
                src={poorder}
                alt="poorder"
              />
              </div>
              <div>
                <span>279</span>
                <p>PO Order</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xs="12" sm="6" md="3">
        <Card>
          <CardBody>
            <div className="d-flex usr-info">
              <div>
                 <img
                id="main-logo"
                className="usr-invs d-inline-block align-top mr-2"
                style={{ maxWidth: "100px" }}
                src={invoice}
                alt="invoice"
              />
              </div>
              <div>
                <span>246</span>
                <p>Invoice Created</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xs="12" sm="6" md="3">
        <Card>
          <CardBody>
            <div className="d-flex usr-info">
              <div>
                 <img
                id="main-logo"
                className="usr-pymt d-inline-block align-top mr-2"
                style={{ maxWidth: "100px" }}
                src={payment}
                alt="user"
              />
              </div>
              <div>
                <span>44</span>
                <p>Payment Paid</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row> */}
    <Card className="mt-3 mb-3">
      <CardBody>
        <Row noGutters className="page-header pb-3">
          <PageTitle title="Dashboard" subtitle="" className="text-left mb-3" />
        </Row>
        <Row>
          {smallStats.map((stats, idx) => (
            <Col className="col-lg mb-2" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
    <div className="mt-0 mb-2">
      <Row>

        <Col xs="12" sm="6" md="8">
          <Card>
            <CardBody className="crd-info">
              <div className="d-flex mb-3">
                <Col md="6">
                  <span>Summary</span>
                </Col>

                <Col md="6">
                  <span className="input-group">
                    <i className="fa fa-search dash ds-brd" />
                    <input type="search" placeholder="Vendor Name / Vendor Code" aria-describedby="button-addon3" className="form-control search-dash-summary ds-brd"/>

                  </span>
                </Col>

              </div>
              <div className="actvty-tble">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>VENDOR CODE</th>
                      <th>VENDOR NAME</th>
                      <th>PO</th>
                      <th>INVOICE</th>
                      <th>PAYMENTS</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>V04683</td>
                      <td>Computer Exchange Pvt Ltd</td>
                      <td className="cnt-lnk">15</td>
                      <td className="cnt-lnk">11</td>
                      <td className="cnt-lnk">10</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04685</td>
                      <td>Sri Ganesh Offset Printers</td>
                      <td className="cnt-lnk">30</td>
                      <td className="cnt-lnk">30</td>
                      <td className="cnt-lnk">24</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04827</td>
                      <td>Pine Labs Private Limited</td>
                      <td className="cnt-lnk">3</td>
                      <td className="cnt-lnk">3</td>
                      <td className="cnt-lnk">3</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04465</td>
                      <td>Saptagiri Enterprise</td>
                      <td className="cnt-lnk">5</td>
                      <td className="cnt-lnk">4</td>
                      <td className="cnt-lnk">4</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V03735</td>
                      <td>Weaving Manpower Sloutions Pvt Ltd</td>
                      <td className="cnt-lnk">0</td>
                      <td className="cnt-lnk">0</td>
                      <td className="cnt-lnk">0</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V00229</td>
                      <td>Aradhana Enterprise</td>
                      <td className="cnt-lnk">5</td>
                      <td className="cnt-lnk">4</td>
                      <td className="cnt-lnk">2</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04671</td>
                      <td>Printo Document Services Private Limited</td>
                      <td className="cnt-lnk">16</td>
                      <td className="cnt-lnk">15</td>
                      <td className="cnt-lnk">15</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04693</td>
                      <td>7 Hills PG Accomdation</td>
                      <td className="cnt-lnk">5</td>
                      <td className="cnt-lnk">8</td>
                      <td className="cnt-lnk">2</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04663</td>
                      <td>Sri Ranganatha Green Traders</td>
                      <td className="cnt-lnk">4</td>
                      <td className="cnt-lnk">6</td>
                      <td className="cnt-lnk">12</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04713</td>
                      <td>Yashvi Enterprises</td>
                      <td className="cnt-lnk">6</td>
                      <td className="cnt-lnk">5</td>
                      <td className="cnt-lnk">2</td>
                      <td className="cnt-lnk">View</td>
                    </tr>
                    <tr>
                      <td>V04723</td>
                      <td>HiveMinds Innovative Market Slutions Pvt Ltd</td>
                      <td className="cnt-lnk">9</td>
                      <td className="cnt-lnk">10</td>
                      <td className="cnt-lnk">14</td>
                      <td className="cnt-lnk">View</td>
                    </tr>

                  </tbody>
                </Table>
              </div>

            </CardBody>
          </Card>
        </Col>
        <Col xs="12" sm="6" md="4">
          <Card>
            <CardBody className="crd-info">
              <div className="d-flex mb-3">
              <Col md="6">
                <span className="rct-cnt">Recent Activity</span>
              </Col>

              <Col md="6">
                <div className="w-100">
                  <select className="form-control search-dash-summary">
                    <option value="0">Select</option>
                    <option value="1">Admin</option>
                    <option value="2">IT</option>
                    <option value="3">Manager</option>
                  </select>
                </div>
              </Col>
              </div>

              <div className="actvty-crd">
                <div className="mn-cnt">
                  <div className="crd-dat mb-2">
                    <>
                      <div className="actvty-cnt">
                            <div className="d-flex usr-info">
                              <div>
                                <img
                                className="usr-po d-inline-block align-top mr-2"
                                style={{ maxWidth: "100px" }}
                                src={poimg}
                                alt="poorder"
                              />
                              </div>
                              <div>
                                <p>Portea has raised PO 10031 against Omega Business System for a value of Rs.5510</p>
                              </div>
                            </div>


                      </div>
                    </>
                  </div>
                  <div className="crd-dat mb-2">
                    <>
                      <div className="actvty-cnt">
                            <div className="d-flex usr-info">
                              <div>
                                <img
                                className="usr-po d-inline-block align-top mr-2"
                                style={{ maxWidth: "100px" }}
                                src={poimg}
                                alt="poorder"
                              />
                              </div>
                              <div>
                                <p>Portea has raised PO 10039 against Omega Business System for a value of Rs.5410</p>
                              </div>
                            </div>


                      </div>
                    </>
                  </div>
                  <div className="crd-dat mb-2">
                    <>
                      <div className="actvty-cnt">
                            <div className="d-flex usr-info">
                              <div>
                                <img
                                className="usr-po d-inline-block align-top mr-2"
                                style={{ maxWidth: "100px" }}
                                src={poimg}
                                alt="poorder"
                              />
                              </div>
                              <div>
                                <p>Portea has raised PO 10035 against Omega Business System for a value of Rs.6310</p>
                              </div>
                            </div>


                      </div>
                    </>
                  </div>
                  <div className="crd-dat mb-2">
                    <>
                      <div className="actvty-cnt">
                            <div className="d-flex usr-info">
                              <div>
                                <img
                                className="usr-po d-inline-block align-top mr-2"
                                style={{ maxWidth: "100px" }}
                                src={poimg}
                                alt="poorder"
                              />
                              </div>
                              <div>
                                <p>Portea has raised PO 10040 against Omega Business System for a value of Rs.7310</p>
                              </div>
                            </div>


                      </div>
                    </>
                  </div>
                  <div className="crd-dat mb-2">
                    <>
                      <div className="actvty-cnt mt-4">
                            <div className="d-flex usr-info">
                              <div>
                                <img
                                className="usr-po d-inline-block align-top mr-2"
                                style={{ maxWidth: "100px" }}
                                src={poimg}
                                alt="poorder"
                              />
                              </div>
                              <div>
                                <p>Portea has raised PO 10036 against Omega Business System for a value of Rs.5310</p>
                              </div>
                            </div>


                      </div>
                    </>
                  </div>
                </div>
              </div>
        </CardBody>
        </Card>
        </Col>
      </Row>
    </div>
  </Container>
);

Vendorlogindetails.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

Vendorlogindetails.defaultProps = {
  smallStats: [
    {
      label: "Active Vendors",
      value: "44",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "PO Order",
      value: "279",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Invoice Created",
      value: "246",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "Payment Paid",
      value: "44",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },

  ]
};


export default Vendorlogindetails;

