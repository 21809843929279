import React, { useState, useEffect} from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import PageTitle from "../components/common/PageTitle";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import ReactPaginate from "react-paginate";
import { withRouter, useParams } from 'react-router-dom';
import ApprovalmatrixDetails from "../views/ApprovelmatrixDetails";

const Approvelmatrix = ({ history }) => {
   const [pagewrap, setPageWrap] = useState({
    style: "",
  });
  const [invoicedata, setInvoicedata] = useState([]);
  const [viewdetails, setViewdetails] = useState(true);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [pageDataSize, setPageDataSize] = useState("");
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [approvalDocEntry, setApprovalDocEntry] = useState("");
  const [approvalLineDetails, setApprovalLineDetails] = useState(false);

  const [searchval, setSearchval] = useState("");

  // useEffect(() => {
  //   setFilteredData(invoicedata);
  // }, [invoicedata]);

  // const searchData = () => {
  //       if (searchval) {
  //           let filterData = invoicedata.filter(item =>
  //               item._id.toString().startsWith(searchval.toString()) ||
  //               item.vendor_name.toLowerCase().includes(searchval.toLowerCase())
  //           );
  //           setFilteredData(filterData);
  //       } else {
  //           setFilteredData(invoicedata);
  //       }
  //   };

  useEffect(() => {
    setFilteredData(invoicedata);
  }, [invoicedata]);

  const searchData = () => {
    if (searchval) {
      let filterData = invoicedata.filter(item =>
        item._id.toString().startsWith(searchval.toString()) ||
        item.vendor_name.toLowerCase().includes(searchval.toLowerCase())
      );
      setFilteredData(filterData);
    } else {
      setFilteredData(invoicedata);
    }
  };

  const handleClearData = () => {
    setSearchval('');
    setFilteredData(invoicedata);
  };

  // const searchData = (e) => {
  //   const value = e.target.value.toLowerCase();
  //   setSearchval(value);

  //   let filterData = invoicedata.filter(
  //     (searchVal) =>
  //     let filterData = vendordata.filter(item =>
  //     item._id.toString().startsWith(value) ||
  //       (item.vendor_name.toLowerCase().includes(value))
  //   );

  //   setFilteredData(value ? filterData : invoicedata);
  // };

  useEffect(() => {
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);
    setFromDate(thirtyDaysAgo);
  }, []);

  const PageSize = 20;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  const handleButtonClick = (purchaseId) => {
    // history.push(`/approvel-matrix-details/${paymentId}/${docId}`);
    history.push('/approvel-matrix-details/' + purchaseId);
  };
  const goBacktopurchaseorder = (e) => {
    setViewdetails(!viewdetails);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDated, setSelectedDated] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChanged = (date) => {
    setSelectedDated(date);
  };



  // console.log("PAGENO", currentPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let userType = sessionStorage.getItem("loginType");
  let empCode = sessionStorage.getItem("login-code");


  const handleCloseAprlLItems = () =>
    setApprovalLineDetails(!approvalLineDetails);

  useEffect(() => {
    fetchapproveldata();
  }, [fromDate, toDate, currentPage, PageSize]);

  const fetchapproveldata = () => {
    let formatFromDate = moment(new Date(fromDate)).format("YYYY-MM-DD")
    let formatToDate = moment(new Date(toDate)).format("YYYY-MM-DD")
    let requestOption = {
       method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }

    fetch(
      `https://portea.betaphase.in/invoice_savejson_matching_owners/for_approval_matrix_with_employee_code?start_date=${formatFromDate}&end_date=${formatToDate}&search=&page_num=${currentPage}&page_size=${PageSize}&emp_code=${empCode}`,
      requestOption
    )
    .then((res) => res.json())
      .then((res) => {
        // console.log(res.data, "response")
            // if(res.data.length !== 0){
              setInvoicedata(res.data);
            // }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });

  };

  const viewPDFLink = (e) => {
    window.open(e, "_blank");
  };
return (
   <>
  {userType === "Admin" ||
      userType === "admin" ||
      userType === "approver" ||
      userType === "Approver" ? (
      <Container fluid className="main-content-container px-4">
        <Row>
          <Col xs="12" sm="12" md="12">
                <Card className="mt-3">
                  <CardBody className="pr-order">
                    <Row noGutters className="page-header pb-3">
                      <PageTitle sm="4" title="Approval Matrix" subtitle="" md="4" className="text-sm-left" />
                    </Row>
                    <Row>
                      <Col xs="12" sm="3" md="3">
                        <div className="inf-cnt mb-4">
                          <div className="w-100">
                            <div className="dt-cnt">
                              <div className="dt-cnt">
                                <DatePicker
                                selected={fromDate}
                                  className="select from-date"
                                  dateFormat="dd/MM/yyyy"
                                  startDate={fromDate}
                                  endDate={toDate}
                                  onChange={(date) => setFromDate(date)}
                                  maxDate={new Date()}
                                />
                              <i class='fas fa-calendar-alt'></i>
                            </div>

                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" sm="3" md="3">
                        <div className="inf-cnt mb-4">
                          <div className="w-100">
                            <div className="dt-cnt">
                              <div className="dt-cnt">
                                <DatePicker
                                selected={toDate}
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                startDate={fromDate}
                                endDate={toDate}
                                minDate={fromDate}
                                onChange={(date) => setToDate(date)}
                                maxDate={new Date()}
                              />
                              <i class='fas fa-calendar-alt'></i>
                            </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" sm="3" md="3">
                      <div className="inf-cnt example">
                       <input type="text" placeholder="Search.." name="search2" value={searchval} onChange={(e) => setSearchval(e.target.value)} />
                        <button type="submit" onClick={searchData}><i className="fa fa-search"></i></button>
                      </div>
                      </Col>

                      <Col xs="12" sm="3" md="3">

                        <button className="pr-order btn btn-outline-primary float-right porder-button" onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", borderRadius:"0.25rem", float:"right", marginRight:"0px"}}>
                          <span className="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", marginRight:"5px"}}>clear</span>Clear
                        </button>
                      </Col>

                    </Row>
                    {/* <div className="prd-dta-tble">
                      <div className="table-wrapper-scroll-x my-custom-scrollbar vendor-scroll">
                        <table
                          className="table vendor-table"
                          id="vendor-table-data"
                        >
                          <thead className="approval-head">
                            <tr>
                              <th className="approval-thead">PO.DocEntry</th>
                              <th className="approval-thead">Vendor Ref.No</th>
                              <th className="approval-thead">PO Date</th>
                              {userType !== "vendor" || userType !== "Vendor" ? (
                                <th className="approval-thead">Vendor Name</th>
                              ) : (
                                ""
                              )}
                              <th
                                className="approval-thead"
                                style={{ textAlign: "center" }}
                              >
                                Attachment
                              </th>
                              {userType !== "vendor" ||
                              userType !== "Vendor" ? (
                                <th className="approval-thead">Action</th>
                              ) : (
                                ""
                              )}
                            </tr>
                          </thead>
                           <tbody className="approval-body">
                              {invoicedata.length > 0 ? (
                                invoicedata.map((item) => (
                                  <tr key={item}>
                                    <td className="approval-tbody">
                                      <span
                                        className="approval-tbody-act"
                                      >
                                        {item._id}

                                      </span>
                                    </td>
                                    <td className="approval-tbody">
                                      {item.vendor_ref_no}
                                    </td>
                                    <td className="approval-tbody">
                                      {moment(item.po_date).format("DD/MM/YYYY")}
                                    </td>
                                    <td className="approval-tbody">
                                      {item.vendor_name}
                                    </td>
                                    <td className="approval-tbody" style={{ textAlign: "center" }}>
                                      <i
                                        className="fa fa-paperclip"
                                        title="View Attachment"
                                        onClick={() => viewPDFLink(item.Attachment)}
                                      />
                                    </td>
                                    <td className="approval-tbody">
                                      <button className="btn-secondary porder-act"
                                      onClick={() => handleButtonClick(item._id)}

                                      >
                                        <i className="bi bi-eye-fill" /> View
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="6" className="no-data-found">
                                    ---- No Data Found ----
                                  </td>
                                </tr>
                              )}
                            </tbody>
                        </table>
                      </div>
                    </div> */}

                    <div className="prd-dta-tble">
        <div className="table-wrapper-scroll-x my-custom-scrollbar vendor-scroll">
          <table className="table vendor-table" id="vendor-table-data">
            <thead className="approval-head">
              <tr>
                <th className="approval-thead">PO.DocEntry</th>
                <th className="approval-thead">Vendor Ref.No</th>
                <th className="approval-thead">PO Date</th>
                {userType !== "vendor" && userType !== "Vendor" && (
                  <th className="approval-thead">Vendor Name</th>
                )}
                <th className="approval-thead" style={{ textAlign: "center" }}>Attachment</th>
                {userType !== "vendor" && userType !== "Vendor" && (
                  <th className="approval-thead">Action</th>
                )}
              </tr>
            </thead>
            <tbody className="approval-body">
              {filteredData.length > 0 ? (
                filteredData.map((item) => (
                  <tr key={item._id}>
                    <td className="approval-tbody">
                      <span className="approval-tbody-act">{item._id}</span>
                    </td>
                    <td className="approval-tbody">{item.vendor_ref_no}</td>
                    <td className="approval-tbody">{moment(item.po_date).format("DD/MM/YYYY")}</td>
                    {userType !== "vendor" && userType !== "Vendor" && (
                      <td className="approval-tbody">{item.vendor_name}</td>
                    )}
                    <td className="approval-tbody" style={{ textAlign: "center" }}>
                      <i className="fa fa-paperclip" title="View Attachment" onClick={() => viewPDFLink(item.Attachment)} />
                    </td>
                    {userType !== "vendor" && userType !== "Vendor" && (
                      <td className="approval-tbody">
                        <button className="btn-secondary porder-act" onClick={() => handleButtonClick(item._id)}>
                          <i className="bi bi-eye-fill" /> View
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="no-data-found">
                    ---- No Data Found ----
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
              </CardBody>

              <div className="porder-down d-flex">
                          <span className="page-range">
                            <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                            &nbsp; of &nbsp;
                            <p>{totalemployee}</p>
                          </span>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-double-right" />}
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel={<i className="fa fa-angle-double-left" />}
                            renderOnZeroPageCount={null}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                          />
                        </div>
                          </Card>
                    </Col>
                  </Row>
                </Container>
                ) : (
                  <ApprovalmatrixDetails
                                  approvalLineDetails={approvalLineDetails}
                                  approvalDocEntry={approvalDocEntry}
                                  pagewrap={pagewrap}
                                  handleCloseAprlLItems={handleCloseAprlLItems}
                                />

                )}
            </>
);
}

export default withRouter(Approvelmatrix);
