
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import PageTitle from "./components/common/PageTitle";
import { useParams } from "react-router-dom";
import moment from "moment";

const PaymentStatusDetails = (props) => {
  // const { id } = useParams();
  // let paymentId = id;
  const [paymentstatusdata, setPaymentStatusData] = useState("");
  const [paymentStatusItems, setPaymentStatusItems] = useState([]);
  const { id } = useParams();
  let paymentId = id;
  // const paymentId = props.viewpaymentdata;
  useEffect(() => {
    fetchPaymentDetailsData();
    // fetchPaymentDetailsList();
    let urlId = window.location.pathname;
    console.log(id, "urlId");
  }, [paymentId]);

  const fetchPaymentDetailsData = () => {
    let requestOptions = {
      method: "GET",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
      },
    };
    fetch(
      `https://portea.betaphase.in/payment_statusjson/get_selected_item?pk_payment_num=${paymentId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("DATAVIEW-----:", res);
        console.log("PPITEMS:", res[0].PaymentLines);
        setPaymentStatusData(res[0]);
        setPaymentStatusItems(res[0].PaymentLines);


      })
      .catch((error) => console.error("ERROR:", error));
  };

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row>
        <Col xs="12" sm="12" md="12">
          <Card className="mt-4 mb-4">
            <CardBody className="">
              <Row noGutters className="page-header py-2">
                <Col xs="12" sm="12" md="6">
                  <div className="d-flex align-item-center pr-lft-cnt">
                    <i className="material-icons">chevron_left</i>
                    <h5 className="pgtle" onClick={handleBackClick} style={{paddingLeft:"0px", fontSize:"18px !important;"}}>Payment Status Details</h5>
                  </div>
                </Col>

              </Row>
              {!props.viewdetails ? (
              <div className="pr-dtls-pts card-body">
                <div className={`page-wrapper ${props.pagewrap?.style}`}>
                  <div className="pr-ord-dtls">
                    <div className="row view-paydetails">
                        <div className="col-md-12">
                          <div className="card view-card-body">
                            <div className="card-body py-dtls">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">
                                        PAYMENT NUMBER
                                      </span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {paymentstatusdata?.pk_payment_num}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">BRANCH</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {paymentstatusdata?.branch_name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">VENDOR CODE</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {paymentstatusdata?.fk_vendor_code}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 right-details" >
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">
                                        PAYMENT AMOUNT
                                      </span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {paymentstatusdata?.payment_amount}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">PAYMENT DATE</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {moment(paymentstatusdata?.payment_date).format(
                                        "YYYY/MM/DD"
                                      )}
                                        {/* {paymentstatusdata?.po_date && moment(paymentstatusdata?.po_date).format("DD/MM/YYYY")} */}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 right-details">
                                  <div className="row view1">
                                    <div className="col-1"></div>
                                    <div className="col-5">
                                      <span className="view-head">UTR NUMBER</span>
                                    </div>
                                    <div className="col-6">
                                      <span className="view-body">
                                        :&nbsp;&nbsp;&nbsp;&nbsp;
                                        {paymentstatusdata?.payment_ref}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="row viewpage" style={{marginTop:"30px"}}>
                      <div className="col-lg-12">
                      <table className="table view-table my-custom-scrollbar viewpage-scroll">
                        <thead className="views-thead">
                          <tr>
                            <th className="view-thead">SI DocNum</th>
                            <th className="view-thead">AP DocNum</th>
                            <th className="view-thead">Invoice Amount</th>
                            <th className="view-thead">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentStatusItems !== "" ? paymentStatusItems.map((item, i) => (
                                <tr key={i}>
                                  <td className="view-tbody">
                                    {item?.fk_SI_DocNum}
                                  </td>
                                  <td className="view-tbody">{item?.AP_DocNum}</td>
                                  <td className="view-tbody">{item?.inv_amount}</td>
                                  <td className="view-tbody">{item?.remarks}</td>
                                </tr>
                              ))
                            : " NO DATA FOUND"}
                        </tbody>
                      </table>
                      <div className="dn-ld-ptms-dtls" style={{float:"right", marginTop:"10px"}}>
                        {/* <button
                        type="button"
                        className="pr-order btn btn-outline-primary float-right porder-button"
                        onClick={(e) => exportExcel(paymentdata)}
                        >
                        Download &nbsp;
                        <span class="material-icons">
                          download
                          </span>
                      </button> */}
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ):null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentStatusDetails;

