import React from "react";
import { Link } from "react-router-dom";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink} from "shards-react";

export default class UserActions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  render() {
    const logout=()=>{
      sessionStorage.clear()
      window.location.href="/"
    }
  // const userName = sessionStorage.getItem("login-name");
  const userType = sessionStorage.getItem("loginType");
  // const userType = "Admin";
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/user-image.jpg")}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{userType}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem onClick={logout} className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
