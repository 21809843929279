export const branchmode = [
  {
    name: "Karnataka",
    code: 1,
    city: "Mysore",
  },
  {
    name: "Main",
    code: 2,
  },
  {
    name: "Delhi",
    code: 3,
    city: "Delhi",
  },
  {
    name: "Kerala",
    code: 4,
    city: "Kochi",
  },
  {
    name: "Madhya Pradesh",
    code: 5,
    city: "Indore",
  },
  {
    name: "Chandigarh",
    code: 6,
    city: "Chandigarh",
  },
  {
    name: "Rajasthan",
    code: 7,
    city: "Jaipur",
  },
  {
    name: "Tamil Nadu",
    code: 8,
    city: "Salem",
  },
  {
    name: "Telangana",
    code: 9,
    city: "Hydarabad",
  },
  {
    name: "Uttar Pradesh",
    code: 10,
    city: "",
  },
  {
    name: "West Bengal",
    code: 11,
    city: "Kolkata",
  },
  {
    name: "Maharashtra",
    code: 12,
    city: "Nagpur",
  },
  {
    name: "Punjab",
    code: 13,
    city: "",
  },
  {
    name: "Andhra Pradesh",
    code: 14,
    city: "Vijayawada",
  },
  {
    name: "Gujarat",
    code: 15,
    city: "Ahmedabad",
  },
  {
    name: "Jharkhand",
    code: 16,
    city: "Ranchi",
  },
  {
    name: "Odisha",
    code: 17,
    city: "Bhubaneshwar",
  },
  {
    name: "Assam",
    code: 18,
    city: "Guwahati",
  },
  {
    name: "Uttarakhand",
    code: 19,
    city: "",
  },
  {
    name: "Goa",
    code: 20,
    city: "Margao",
  },
  {
    name: "Puducherry",
    code: 21,
    city: "Puducherry",
  },
];
