import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import PageTitle from "../components/common/PageTitle";
import moment from "moment";
import DatePicker from 'react-datepicker';
import ReactPaginate from "react-paginate";
import { withRouter, useParams } from 'react-router-dom';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const InvoiceStatus = ({history}) => {

  const { vendorid, typeOfview } = useParams();
  const [invoicedata, setInvoicedata] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [invoiceid, setInvoiceid] = useState(0);
  const [searchval, setSearchval] = useState("");
  const [status, setStatus] = useState({});
  const [branch, setBranch] = useState({});
  const [presentDate, setPresentDate] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [isMenuOpen, setIsMenuOpen] = useState({
    style: "menu active",
    menuStatus: "open",
  });
  const [pagewrap, setPageWrap] = useState({
    style: "",
  });
  const [pdfdata, setPdfdata] = useState("");

   useEffect(() => {

    // Calculate the date from 30 days ago
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);

    // Set the state with the calculated date
    setFromDate(thirtyDaysAgo);
  }, []);

  // PAGINATION START
  const PageSize = 20;
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);

  var userType = sessionStorage.getItem("loginType");
  var loginCode = sessionStorage.getItem("login-code");

  // const userType = "Admin";
  // const loginCode = null;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

   const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

useEffect(() => {
    fetchinvoiceData(fromDate, toDate, currentPage, PageSize);
  }, [fromDate, toDate, currentPage, PageSize]);

  const fetchinvoiceData = (fromDate, toDate, currentPage, PageSize) => {
    let formatFromDate = moment(new Date(fromDate)).format("YYYY-MM-DD")
    let formatToDate = moment(new Date(toDate)).format("YYYY-MM-DD")
    let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
      if (userType == "Admin") {
      let url = "";
      if (vendorid) {
        url = `https://portea.betaphase.in/invoice_statusjson/get_selected_item/invoice_status_based_on_vendor_code/?start_date=${formatFromDate}&end_date=${formatToDate}&search=&page_num=${currentPage}&page_size=${PageSize}&fk_vendor_code=${vendorid}`;
      } else {
        url = `https://portea.betaphase.in/invoice_statusjson/pages?start_date=${formatFromDate}&end_date=${formatToDate}&search=&page_num=${currentPage}&page_size=${PageSize}`;
      }

      fetch(url, requestOption)
        .then((res) => res.json())
        .then((res) => {
           if(vendorid){
            if (res) {
              const data = res;
              const tableData = [...data];
              setInvoicedata(tableData);
              setLoading(false);
              setPageDataSize(data.length);
              setTotalemployee(res.length);
              setPageCount(Math.ceil(res.length / PageSize));
          } else {
            setLoading(false);
          }
          } else {
            if (res.data) {
              const data = res.data;
              const tableData = [...data];
              setInvoicedata(tableData);
              setLoading(false);
              setPageDataSize(res.data.length);
              setTotalemployee(res.total);
              setPageCount(Math.ceil(res.total / PageSize));
          } else {
            setLoading(false);
          }
          }

        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
      }else if(userType == "Vendor")

      {
        fetch(
          `https://portea.betaphase.in/invoice_statusjson/get_selected_item/invoice_status_based_on_vendor_code/?start_date=${formatFromDate}&end_date=${formatToDate}&fk_vendor_code=${loginCode}`,
          requestOption
      )

      .then((res) => res.json())
        .then((res) => {
          if (res) {
            const data = res;
            const tableData = [...data];
            setInvoicedata(tableData);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
      }
      else if(userType == "Approver"){
        fetch(
          `https://portea.betaphase.in/invoice_statusjson_matching_owners/from_vendor_table/?start_date=${formatFromDate}&end_date=${formatToDate}&emp_code=${loginCode}`,requestOption
      )

      .then((res) => res.json())
        .then((res) => {
          if (res) {
            setInvoicedata(res.data);
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
      }
  };

  const handleButtonClick = (purchaseId) => {
    // Navigate to the "purchase-orders.js" file or route
    // navigate('/purchase-orders');
    history.push('/purchaseorders-details/' + purchaseId);
  };


//   useEffect(() => {
//     setFilteredData(invoicedata);
//   }, [invoicedata]);

//   const serachData = (e) => {
//   const value = e.target.value.toLowerCase(); // Convert search value to lowercase
//   setSearchval(value); // Update search value state

//   let filterData = invoicedata.filter(
//     (data) =>
//       data.SI_DocNum.toString().startsWith(value) ||
//       data.fk_po_DocEntry.toString().startsWith(value)
//       // Add more conditions for other fields if necessary
//   );

//   setFilteredData(value ? filterData : invoicedata); // Update filtered data state based on search value
// };


  useEffect(() => {
    setFilteredData(invoicedata);
  }, [invoicedata]);

  // Function to handle search
  const searchData = () => {
    const value = searchval.toLowerCase(); // Convert search value to lowercase

    let filterData = invoicedata.filter(
      (data) =>
        data.SI_DocNum.toString().startsWith(value) ||
        data.fk_po_DocEntry.toString().startsWith(value)
      // Add more conditions for other fields if necessary
    );

    setFilteredData(value ? filterData : invoicedata); // Update filtered data state based on search value
  };

  // Handle input change
  const handleInputChange = (e) => {
    setSearchval(e.target.value);
  };


  const [showSearch, setShowSearch] = useState(true);
  console.log(filteredData, "filteredData")
  const [selectedStatus, setSelectedStatus] = useState('');
const [allData, setAllData] = useState(invoicedata); // Assuming invoicedata is available
const [filterData, setFilterData] = useState(allData); // Initialize filterData state

const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);

    if (status === '') {
        setFilterData(allData); // Reset to all data if status is empty
    } else {
        // Filter based on dept_status or fin_status
        const filtered = allData.filter(item => item.dept_status === status || item.fin_status === status);
        setFilterData(filtered);
    }
};

const handleClearData = () => {
    setSearchval('')
    setFilteredData(invoicedata);
  };

   // DOWNLOAD EXCEL-FORMATE START
  const fileName = "Invoicestatus-data";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_AP_DocEntry: "INV.DocEntry",
      AP_DocDate: "Doc.Date",
      AP_DocNum: "INV.DocNo",
      AP_DueDate: "Due.Date",
      fk_po_DocEntry: "PO.DocEntry",
      fk_branch: "Branch",
      vendor_name: "Vendor Name",
      payment_amount: "Payment(₹)",
      AP_status: "Status",
    },
  ];

  const exportExcel = () => {
    const newInvoiceData = invoicedata.map((data) => {
      return {
        pk_AP_DocEntry: data.pk_AP_DocEntry,
        AP_DocDate: moment(data.AP_DocDate).format("DD/MM/YYYY"),
        AP_DocNum: data.AP_DocNum,
        AP_DueDate: moment(data.AP_DueDate).format("DD/MM/YYYY"),
        fk_po_DocEntry: data.fk_po_DocEntry,
        fk_branch: data.fk_branch,
        vendor_name: data.vendor_name[0],
        payment_amount: data.payment_amount,
        AP_status: data.AP_status,
      };
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_AP_DocEntry",
        "AP_DocDate",
        "AP_DocNum",
        "AP_DueDate",
        "fk_po_DocEntry",
        "fk_branch",
        "vendor_name",
        "payment_amount",
        "AP_status",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, newInvoiceData, {
      header: [
        "pk_AP_DocEntry",
        "AP_DocDate",
        "AP_DocNum",
        "AP_DueDate",
        "fk_po_DocEntry",
        "fk_branch",
        "vendor_name",
        "payment_amount",
        "AP_status",
      ],
      skipHeader: true,
      origin: -1,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END
  return (
  <Container fluid className="main-content-container px-4 pb-4">

    <Row>
    <Col xs="12" sm="12" md="12">
          <Card className="mt-3">
            <CardBody className="invs-sts">
              <Row noGutters className="page-header pb-3">
                <PageTitle title="Invoice Status" subtitle="" md="4" className="mr-sm-auto" />
              </Row>

              <Row>
              <Col xs="12" sm="3" md="3">
                      <div className="inf-cnt mb-4">
                        <div className="w-100">
                          <div className="dt-cnt">
                             <div className="dt-cnt">
                              <DatePicker
                              selected={fromDate}
                                // selectsStart
                                className="select from-date"
                                dateFormat="dd/MM/yyyy"
                                startDate={fromDate}
                                endDate={toDate}
                                onChange={(date) => setFromDate(date)}
                                maxDate={new Date()}

                              />
                            <i class='fas fa-calendar-alt'></i>
                          </div>

                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs="12" sm="3" md="3">
                      <div className="inf-cnt mb-4">
                        <div className="w-100">
                          <div className="dt-cnt">
                             <div className="dt-cnt">
                              <DatePicker
                              selected={toDate}
                              // selectsEnd
                              className="select from-date"
                              dateFormat="dd/MM/yyyy"
                              startDate={fromDate}
                              endDate={toDate}
                              minDate={fromDate}
                              onChange={(date) => setToDate(date)}
                              maxDate={new Date()}
                            />
                            <i class='fas fa-calendar-alt'></i>
                          </div>


                          </div>
                        </div>
                      </div>
                    </Col>
                <Col xs="12" sm="3" md="3">
                  <div className="inf-cnt example">

                    <input type="text" placeholder="Search.." name="search2" value={searchval} onChange={handleInputChange} />
                    <button type="submit"><i class="fa fa-search" onClick={searchData}></i></button>
                  </div>
                </Col>
                <Col xs="12" sm="3" md="3">

                  <button type="button" className="pr-order btn btn-outline-primary float-right porder-button" onClick={(e) => exportExcel(invoicedata)} style={{marginRight:"0px"}}>
                      Download &nbsp;
                      <span class="material-icons">
                        download
                        </span>
                    </button>

                      <button className="pr-order btn btn-outline-primary float-left porder-button" onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", borderRadius:"0.25rem"}}>
                        <span class="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", marginRight:"5px"}}>clear</span>Clear
                      </button>
                </Col>
              </Row>
              <div className="invs-tble">

                 <div className="invoice-status">
                    <div className="table-wrapper-scroll-x my-custom-scrollbar istatus-scroll">
                      <table
                        className="table istatus-table"
                        id="invstatus-table"
                      >
                        <thead className="invoice-head">
                          <tr>
                            <th className="invoice-thead">INV.DocNo</th>
                            <th className="invoice-thead">Vendor Ref.No</th>
                            <th className="invoice-thead">Doc.Date</th>
                            <th className="invoice-thead">PO.DocEntry</th>
                            {userType === "vendor" || userType === "Vendor" ? (
                              ""
                            ) : (
                              <th className="invoice-thead">Vendor Name</th>
                            )}
                            {/* <th className="invoice-thead">Branch</th> */}
                            <th className="invoice-thead">Payment(&#8377;)</th>
                            {/* <th className="invoice-thead">Due.Date</th> */}
                            <th className="invoice-thead">Dept.Status</th>
                            <th className="invoice-thead">Fin.Status</th>
                            {/* <th className="invoice-thead">Action</th> */}
                          </tr>
                        </thead>
                        <tbody className="invoice-body">
                          {filteredData.length !== 0 ?(
                              filteredData.map((item, i) => (
                                <tr key={i}>
                                  <td className="invsta-tbody">
                                    <span className="insta-act">
                                      {item?.SI_DocNum}
                                    </span>
                                  </td>
                                  <td className="invoice-tbody">
                                    {item?.vendor_ref_no}
                                  </td>
                                  <td className="invoice-tbody">
                                    {moment(item?.AP_DocDate).format(
                                      "YYYY/MM/DD"
                                    )}
                                  </td>
                                  <td className="invoice-tbody num-entry" onClick={(e) => handleButtonClick(item?.fk_po_DocEntry)}>
                                     {item?.fk_po_DocEntry}

                                  </td>
                                  {userType === "vendor" ||
                                  userType === "Vendor" ? (
                                    ""
                                  ) : (
                                    <td className="invoice-tbody">
                                      {item?.vendor_name}
                                    </td>
                                  )}
                                  <td className="invoice-tbody">
                                    {item?.payment_amount}
                                  </td>
                                  {item?.dept_status === "Approved" ? (
                                    <td className="tab-body act">
                                      <span className="dot-sta-closed">
                                        {item?.dept_status}
                                      </span>
                                    </td>
                                  ) : (
                                    <td className="tab-body act">
                                      <span className="dot-sta-open">
                                        {item?.dept_status}
                                      </span>
                                    </td>
                                  )}
                                  {item?.fin_status === "Approved" ? (
                                    <td className="tab-body act">
                                      <span className="dot-sta-closed">
                                        {item?.fin_status}
                                      </span>
                                    </td>
                                  ) : (
                                    <td className="tab-body act">
                                      <span className="dot-sta-open">
                                        {item?.fin_status}
                                      </span>
                                    </td>
                                  )
                                }
                                </tr>
                              ))
                          ) : (
                            <>
                              {invoicedata !== "" || invoicedata !== null ? (
                                invoicedata
                                  .filter((inv) =>
                                    // inv?.SI_DocNum?.includes(searchval) ||
                                    inv?.fk_po_DocEntry.includes(searchval)
                                  )
                                  .map((item, i) => (
                                    <tr key={i}>
                                      <td className="invsta-tbody">
                                        <span className="insta-act">
                                          {item?.SI_DocNum}
                                        </span>
                                      </td>
                                      <td className="invoice-tbody">
                                        {moment(item?.AP_DocDate).format(
                                          "YYYY/MM/DD"
                                        )}
                                      </td>
                                      <td className="invoice-tbody">
                                        {item?.fk_po_DocEntry}
                                      </td>
                                      {userType !== "vendor" ||
                                      userType !== "Vendor" ? (
                                        <td className="invoice-tbody">
                                          {item?.vendor_name}
                                        </td>
                                      ) : (
                                        ""
                                      )}

                                      {/* <td className="invoice-tbody">
                                        {item?.fk_branch}
                                      </td> */}
                                      <td className="invoice-tbody">
                                        {item?.payment_amount}
                                      </td>
                                      {/* <td className="invoice-tbody">
                                      {moment(item?.AP_DueDate).format(
                                        "YYYY/MM/DD"
                                      )}
                                    </td> */}

                                      {item?.dept_status === "Approved" ? (
                                        <td className="tab-body act">
                                          <span className="dot-sta-closed">
                                            {item?.dept_status}
                                          </span>
                                        </td>
                                      ) : (
                                        <td className="tab-body act">
                                          <span className="dot-sta-open">
                                            {item?.dept_status}
                                          </span>
                                        </td>
                                      )}
                                      {item?.fin_status === "Approved" ? (
                                        <td className="tab-body act">
                                          <span className="dot-sta-closed">
                                            {item?.fin_status}
                                          </span>
                                        </td>
                                      ) : (
                                        <td className="tab-body act">
                                          <span className="dot-sta-open">
                                            {item?.fin_status}
                                          </span>
                                        </td>
                                      )}


                                    </tr>
                                  ))
                              ) : (
                                <span className="no-data-found">
                                  ---- No Data Found ----
                                </span>
                              )}
                            </>
                          )}

                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>

            </CardBody>
            <div className="porder-down d-flex">
                        <span className="page-range">
                          <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                          &nbsp; of &nbsp;
                          <p>{totalemployee}</p>
                        </span>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={<i className="fa fa-angle-double-left" />}
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />

            </div>

          </Card>

        </Col>
    </Row>

  </Container>
  )
};

export default InvoiceStatus;
