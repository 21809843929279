
import React, { useState, useEffect} from 'react';
import mnlogo from './../images/vhd-logo.svg';
import dmlogo from './../images/whi-plus.png';
import dmlogonew from './../images/col-plus.png';
import './../assets/css/my.css';
// import { useNavigate } from "react-router-dom";

function Login() {

  const [Isotpsent, setIsotpsent] = useState(false);
  const [email, setEmail] = useState("");
  const [genOTPStatus, setGenOTPStatus] = useState(false);
  const [disablestatus, setDisablestatus] = useState(true);
  const [enteredOTP, setEnteredOTP] = useState("");
  const [vendorLogins, setVendorLogins] = useState([]);
  const [employeeLogins, setEmployeeLogins] = useState([]);
  const [loginType, setLoginType] = useState({});
  const [loginMessage, setLoginMessage] = useState("");
  const [verifyEmail, setVerifyEmail] = useState(true);
   const [error, setError] = useState('');
   const [successMessage, setSuccessMessage] = useState('');

  //  const userType = sessionStorage.getItem("loginType");
  // const vendorCode = sessionStorage.getItem("login-code");


  // const loginvalidat = (e) => {
  //   e.preventDefault();
  //   if(email === "mahesh.k@lobotus.in"){
  //     sessionStorage.setItem("loginType", "Admin");
  //     sessionStorage.setItem("login-code", "973");
  //     sessionStorage.setItem("login-name", "Mahesh K");
  //     handleOTPSubmit();
  //   }else if(email === "justyuvacool@gmail.com"){
  //     sessionStorage.setItem("loginType", "Vendor");
  //     sessionStorage.setItem("login-code", "V04915");
  //     sessionStorage.setItem("login-name", "Yuvaraj");
  //     handleOTPSubmit();
  //   }else if (email === "k.mahesh846@gmail.com"){
  //     sessionStorage.setItem("loginType", "Approver");
  //     sessionStorage.setItem("login-code", "968");
  //     sessionStorage.setItem("login-name", "Karteek");
  //     handleOTPSubmit();
  //   }else{
  //     //  setLoginMessage("Email Id is not registered");
  //      if(!email){
  //       setLoginMessage("Enter your email");

  //      }
  //      setLoginMessage("Email Id is not registered");

  //       // After 3 seconds, reset login message
  //       setTimeout(() => {
  //           setLoginMessage("");
  //       }, 3000);
  //   }

  //       // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //       // if (email.trim() === '' || !emailRegex.test(email)) {
  //       //     setError('Please enter a valid email or User Name');
  //       //     setSuccessMessage('');
  //       //     return;
  //       // }

  //       // setGenOTPStatus(true);
  //       // setError('');
  //       // setSuccessMessage('Login successful!');


  //       // Simulate an API call to check if the email exists in the database


  //       // If email is registered, proceed with OTP generation or further actions

  //   };

    // Simulated function to check if email is registered


  // MAIN CODE COMMENTED FOR TEST START
  //  console.log(vendorLogins, "vendorLogins")
  //   if (
  //     vendorLogins.some(
  //       (ven) => ven.otp == enteredOTP && ven.email === email
  //     ) ||
  //     employeeLogins.some((emp) => emp.otp == enteredOTP && emp.email === email)
  //   ) {
  //     setLoginMessage("Login Successfull");
  //     if (loginType?.role === "Admin" || loginType?.role === "admin") {
  //       sessionStorage.setItem("loginType", loginType?.role);
  //       sessionStorage.setItem("login-code", loginType?.pk_emp_code);
  //       sessionStorage.setItem("login-name", loginType?.emp_name);
  //       sessionStorage.setItem("email", email);
  //       handleOTPSubmit();
  //     } else if (loginType?.role === "Vendor" || loginType?.role === "vendor") {
  //       sessionStorage.setItem("loginType", loginType?.role);
  //       sessionStorage.setItem("login-code", loginType?.vendor_code);
  //       sessionStorage.setItem("login-name", loginType?.vendor_name);
  //       sessionStorage.setItem("email", email);
  //       handleOTPSubmit();
  //     } else {
  //       if (
  //         loginType?.job_title === "Approver" ||
  //         loginType?.job_title === "approver"
  //       ) {
  //         sessionStorage.setItem("loginType", loginType?.job_title);
  //       } else {
  //         sessionStorage.setItem("loginType", loginType?.role);
  //       }
  //       sessionStorage.setItem("login-code", loginType?.pk_emp_code);
  //       sessionStorage.setItem("login-name", loginType?.emp_name);
  //       sessionStorage.setItem("email", email);
  //       handleOTPSubmit();
  //     }
  //   } else {

  //     setLoginMessage("Invalid OTP, Please enter valid OTP");
  //   }

  // MAIN CODE COMMENTED FOR TEST START

  // const checkOTP = () => {
  //   if (email !== "" && email !== null) {
  //     const emailData = {
  //       email: email,
  //     };
  //     let requestoption = {
  //       method: "POST",
  //       mode: "cors",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(emailData),
  //     };
  //     fetch(
  //       "https://portea.betaphase.in/loginjson/login_users/add_data",
  //       requestoption
  //       )
  //       .then((res) => res.json())
  //       .then((res) => {

  //         if (res.status == 100) {
  //           setGenOTPStatus(true);
  //           setDisablestatus(false);
  //           setLoginType(res?.data);
  //           console.log(res)
  //           setVerifyEmail(res?.message);
  //         } else {
  //           setGenOTPStatus(false);
  //           setDisablestatus(true);
  //           setVerifyEmail(res?.message);
  //         }
  //         if(res.data.role === "vendor"){
  //           fetch(
  //           "https://portea.betaphase.in/vendorjsonmatching_vendor_and_branch/fetching_branch_name/"
  //           )
  //           .then((res) => res.json())
  //           .then((res) => {
  //             setVendorLogins(res.filter((v) => v.email));
  //           });
  //         } else{
  //           fetch("https://portea.betaphase.in/employee_masterjson/get_data")
  //           .then((res) => res.json())
  //           .then((res) => {
  //             setEmployeeLogins(res?.data.filter((e) => e.email));
  //           });
  //         }
  //           setIsotpsent(true)
  //     });
  //   } else {
  //     setVerifyEmail("Please enter email/username");
  //   }
  // };

  const loginvalidat = (e) => {
    e.preventDefault();

    if (!email) {
        setLoginMessage("Enter your email");
        setTimeout(() => {
            setLoginMessage("");
        }, 3000);
        return;
    }

    // Check email against predefined values
    switch (email) {
        case "mahesh.k@lobotus.in":
            sessionStorage.setItem("loginType", "Admin");
            sessionStorage.setItem("login-code", "973");
            sessionStorage.setItem("login-name", "Mahesh K");
            handleOTPSubmit();
            break;
        case "justyuvacool@gmail.com":
            sessionStorage.setItem("loginType", "Vendor");
            sessionStorage.setItem("login-code", "V04915");
            sessionStorage.setItem("login-name", "Yuvaraj");
            handleOTPSubmit();
            break;
        case "k.mahesh846@gmail.com":
            sessionStorage.setItem("loginType", "Approver");
            sessionStorage.setItem("login-code", "965");
            sessionStorage.setItem("login-name", "Karteek");
            handleOTPSubmit();
            break;
        default:
            setLoginMessage("Email Id is not registered");
            setTimeout(() => {
                setLoginMessage("");
            }, 2000);
            break;
    }
};

const handleOTPSubmit = () => {
    setTimeout(() => {
        window.location.href = '/#/dashboard';
        window.location.reload();
    }, 500);
    // window.location.href = '/#/dashboard';
    // window.history.pushState({ path: '/#/dashboard' }, '', '/#/dashboard');
  }

return (
  <div className="bg">
    <div className="container-fluid">
      <br />
      <br />
      <div className="mn-info">

      </div>
      <div className="screen">
        <div className="screen__content">
          <form className="login"
          // onSubmit={loginvalidat}
          >
            <div>
              <img
                id="main-logo"
                className="mn-lg usr-img d-inline-block align-top mr-2 mb-4"
                style={{ maxWidth: '100px', background: '#ffffff' }}
                src={mnlogo}
                alt="user"
              />
            </div>
            <p>Welcome to VENDOR Help Desk</p>
            <span>Login To Your Account</span>
            <div className="login__field">
              <i className="material-icons login__icon">person</i>
              <label>Username</label>
              <input
                type="email"
                className="login__input"
                placeholder="Enter email / Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}

              />
              {/* {error && <p className="error-message">{error}</p>} */}
               {/* <p className={`error-message ${error ? 'fade-in' : ''}`}>{error}</p> */}
                {/* {loginMessage && <div className="login-message">{loginMessage}</div>} */}
                {loginMessage && (
                <div className={`login-message fade-in`} onAnimationEnd={() => setLoginMessage("")}>
                    {loginMessage}
                </div>
            )}
            </div>
            <button
              type="submit"
              className="button login__submit"
              // onClick={checkOTP}
              onClick={loginvalidat}
              disabled={genOTPStatus}
            >
              <span className="button__text">Login</span>
              <i className="material-icons button__icon">chevron_right</i>
            </button>
            {verifyEmail ? (
                      <p className="email-verify-msg">
                        {verifyEmail}
                      </p>
                    ) : (
                      ""
                    )}
                    {Isotpsent &&
                    <div className="otp-login-failed-message" disabled={disablestatus}>
                      <div className="mb-4 pass-box">
                        <label htmlFor="exampleInputPassword1" className="form-label password">
                          Enter OTP
                        </label>
                        <input
                          type="password"
                          className="form-control login"
                          id="password"
                          placeholder="Enter OTP"
                          name="pswd"
                          onChange={(e) => setEnteredOTP(e.target.value)}
                          required
                        />
                      </div>

                      <div className="d-grid gap-2 login-btn">
                        <button className="btn btn-outline-primary login-btn button__text" type="submit"
                        onClick={handleOTPSubmit}
                        >
                          Log in
                        </button>
                      </div>
                    </div>
                    }
                  </form>
              <div className="screen__background">
                <span className="screen__background__shape screen__background__shape4"></span>
                <span className="screen__background__shape screen__background__shape3"></span>
                <span className="screen__background__shape screen__background__shape2"></span>
                <span className="screen__background__shape screen__background__shape1"></span>
                <div className="medical-plusicon">
                  <img
                    className="usr-img d-inline-block align-top mr-2 mb-4"
                    style={{ maxWidth: '100px', background: '#ffffff' }}
                    src={dmlogo}
                    alt="user"
                  />
                  <img
                    className="dmlogonew usr-img d-inline-block align-top mr-2 mb-4"
                    style={{ maxWidth: '100px', background: '#ffffff' }}
                    src={dmlogonew}
                    alt="user"
                  />
                </div>
              </div>
        </div>
      </div>
    </div>

  </div>
);
}
export default Login;

