import React, { useState, useEffect} from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import Table from 'react-bootstrap/Table';
import PageTitle from "../components/common/PageTitle";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import ReactPaginate from "react-paginate";
import { withRouter, useParams } from 'react-router-dom';
import ApproveInvoiceModel from "../views/ApproveInvoiceModel";
import AlertMessageModel from "../views/AlertMessageModel";

const ApprovelmatrixDetails = ({ history} , props) => {
  console.log(props.approvalDocEntry)
  const [aproveStatus, setApproveStatus] = useState(false);
  const [uId, setUid] = useState(null);
  const [invSaveId, setInvSaveId] = useState(null);
  const [vhdId, setVhdId] = useState("");
  const [invoicedata, setInvoicedata] = useState([]);
  const [viewdetails, setViewdetails] = useState(true);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [pageDataSize, setPageDataSize] = useState("");
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  // const [purchaseId, setApprovalDocEntry] = useState("");
  const [approvalLineDetails, setApprovalLineDetails] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [modelShow, setModelShow] = useState(false);
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");

  const handleClose = () => setApproveStatus(!aproveStatus);

  const handleAlertClose = () => setModelShow(false);

  const alertClosesuto = () => {
    setTimeout(() => setModelShow(false), 2000);
  };

  useEffect(() => {
    fetchInvoiceData();
  }, [aproveStatus]);

  useEffect(() => {
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);
    setFromDate(thirtyDaysAgo);
  }, []);

  const PageSize = 20;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  const [searchval, setSearchval] = useState("");
  // console.log("PAGENO", currentPage);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [lineNumber, setLineNumber] = useState([]);
  const [itemId, setItemId] = useState("");
  let selectedItem = [];

  const userType = sessionStorage.getItem("loginType");

  if (userType === "Approver" || userType === "approver") {
    var empId = sessionStorage.getItem("login-code");
  }

  const { id } = useParams();
  let purchaseId = id;
  useEffect(() => {
     let urlId = window.location.pathname;
    console.log(id, "urlId");

      fetchInvoiceData();

  }, [empId, purchaseId]);
   const fetchInvoiceData = () => {
    let empCode
    let requestOption = {
       method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
    if (userType === "Approver" || userType === "approver") {
      fetch(
        `https://portea.betaphase.in/invoice_savejson_matching_owners/from_vendor_table_invoice_from_emp/?emp_code=${empId}&fk_po_DocEntry=${purchaseId}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log(res.data, "response")
            // if(res.data.length !== 0){
              setInvoicedata(res);
            // }

        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || "An error occurred");
          setLoading(false);
        });
     }
  };
   useEffect(() => {
    for (var i = 0; i < invoicedata.length; i++) {
      for (var k = 0; k < invoicedata[i].DocumentLines.length; k++) {
        for (var j = 0; j < lineNumber.length; j++) {
          if (
            invoicedata[i].invoice_save_id == lineNumber[j].invoiceId &&
            invoicedata[i].DocumentLines[k].id == lineNumber[j].docLineId &&
            lineNumber[j].lineStatus === "Approved" &&
            invoicedata[i].DocumentLines[k].fk_po_DocEntry ==
            purchaseId
          ) {
            // console.log("invoicedata[i]", invoicedata[i]);
            selectedItem.push({
              ItemCode: invoicedata[i].DocumentLines[k].ItemCode,
              Quantity: invoicedata[i].DocumentLines[k].Qty,
              Price: invoicedata[i].DocumentLines[k].UnitRate,
              Currency: invoicedata[i].DocumentLines[k].Currency,
              DiscountPercent: invoicedata[i].DocumentLines[k].Discount,
              WarehouseCode: invoicedata[i].DocumentLines[k].fk_whs_code,
              UoMCode: invoicedata[i].DocumentLines[k].UOM,
              BaseEntry: invoicedata[i].DocumentLines[k].fk_po_DocEntry,
              BaseLine: invoicedata[i].DocumentLines[k].LineNum,
              BaseType: 22,
            });
          }
        }
      }
    }
  }, [lineNumber, invoicedata, selectedItem]);

  console.log("SAP-LINE_ITEMS", selectedItem);

  const setlineNumber = (e) => {
    if (e.target.checked) {
      setLineNumber([
        ...lineNumber,
        {
          invoiceId: e.target.value,
          docLineId: e.target.name,
          lineStatus: e.target.id,
        },
      ]);
    } else {
      setLineNumber(
        lineNumber.filter((value) => value.docLineId !== e.target.name)
      );
    }
    setItemId(e.target.name);
  };
  const sendMultipleLineItems = () => {
    let empId
    let requestOption = {
       method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
    fetch(
      `https://portea.betaphase.in/invoice_savejson_matching_owners/from_vendor_table_invoice_from_emp/?emp_code=${empId}&fk_po_DocEntry=${purchaseId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        let approveRejectData = res[0];

        const draftdata = {
          CardCode: approveRejectData.fk_vendor_code,
          DocDate: approveRejectData.po_date,
          DocDueDate: approveRejectData.po_DueDate,
          DocObjectCode: "oPurchaseInvoices",
          DocType: "dDocument_Items",
          ShipToCode: approveRejectData.shipto,
          PayToCode: approveRejectData.billto,
          BPL_IDAssignedToInvoice: JSON.stringify(approveRejectData.fk_branch),
          BaseEntry: approveRejectData.DocumentLines[0].fk_po_DocEntry,
          NumAtCard: approveRejectData.NumAtCard,
          U_IKVHDID: JSON.stringify(approveRejectData.invoice_save_id),
          Attachment: approveRejectData.Attachment,
          DocumentLines: selectedItem,
        };
        // console.log("SEND-PORTEA-DATA:", draftdata);

        let requestoption = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(draftdata),
        };

        fetch(`https://portea.betaphase.in/vendorapi/api/Portea/PostMsg`, requestoption)
          .then((res) => res.json())
          .then((res) => {
            console.log("STATUS-INVOICE-SENT:", res);
            if (res.message === "Created") {
              setResponseStatus(res.status);
              setModelShow(!modelShow);
              setSubmitSuccessMessage(res.message);

            } else {
              setModelShow(!modelShow);
              setSubmitMessage(res.message);

            }
          });
      });
  };

  const handleBackClick = () => {
    window.history.back();
  };
   return (
    <Container fluid className="main-content-container px-4">
      <Row>
        <Col xs="12" sm="12" md="12">
          <Card className="mt-4 mb-4">
            <CardBody className="">
                <Row noGutters className="page-header py-2">
                  <Col xs="12" sm="12" md="6">
                    <div className="d-flex align-item-center pr-lft-cnt">
                      <i className="material-icons">chevron_left</i>
                      <h5 className="pgtle" onClick={handleBackClick} style={{paddingLeft:"0px", fontSize:"18px !important;"}}>Approval Details</h5>
                    </div>
                  </Col>
                </Row>
                  <div className="prd-dta-tble">
                    <div className="row approval-status-line-items">
                      <div className="col-md-12">
                        <div className="table-wrapper-scroll-x my-custom-scrollbar approval-scroll">
                          <table
                            className="table approval-table"
                            id="invstatus-table"
                          >
                            <thead className="approval-head">
                              <tr>
                                <th className="approval-thead">PO.DocEntry</th>
                                {userType !== "vendor" || userType !== "Vendor" ? (
                                  <th className="approval-thead">Vendor Name</th>
                                ) : (
                                  ""
                                )}
                                <th className="approval-thead">Qty</th>
                                <th className="approval-thead">UnitRate</th>
                                <th className="approval-thead">UOM</th>
                                <th className="approval-thead">Vendor Ref.No.</th>
                                <th className="approval-thead">Dept. Status</th>
                                <th className="approval-thead">Remarks</th>
                                {userType !== "vendor" || userType !== "Vendor" ? (
                                  <th className="approval-thead">Action</th>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </thead>
                            <tbody className="approval-body">
                            {invoicedata && invoicedata.length > 0 ? (
                              invoicedata.map((item, i) => (
                                <tr key={i}>
                                  <td className="approval-tbody">
                                    <span className="aplsta-act">
                                      <input
                                        type="checkbox"
                                        className="input-approve-checkbox"
                                        disabled={item.dept_status === "Approved"}
                                        name={item.id}
                                        id={item.dept_status}
                                        value={item.invoice_save_id}
                                        onClick={(e) => setlineNumber(e)}
                                      />
                                      {item?.fk_po_DocEntry}
                                    </span>
                                  </td>
                                  {userType !== "vendor" && userType !== "Vendor" && (
                                    <td className="approval-tbody">{item.vendor_name}</td>
                                  )}
                                  <td className="approval-tbody">{item.Qty}</td>
                                  <td className="approval-tbody">{item.UnitRate}</td>
                                  <td className="approval-tbody">{item.UOM}</td>
                                  <td className="approval-tbody">{item.NumAtCard}</td>
                                  <td
                                    className={
                                      item.dept_status.toLowerCase() === "pending"
                                        ? "approval-tbody pending"
                                        : item.dept_status.toLowerCase() === "rejected"
                                        ? "approval-tbody rejected"
                                        : "approval-tbody approve"
                                    }
                                  >
                                    {item.dept_status}
                                  </td>
                                  <td className="approval-tbody">{item.remarks}</td>
                                  {userType !== "vendor" && userType !== "Vendor" && (
                                    <td className="approval-tbody">
                                      <button
                                        className={`btn approve-status ${item.dept_status === "Approved" ? "hide ok" : item.dept_status === "Rejected" ? "hide cancel" : ""}`}
                                        onClick={() => {
                                          setApproveStatus(!aproveStatus);
                                          setVhdId(item.fk_po_DocEntry);
                                          setUid(item.id);
                                          setInvSaveId(item.invoice_save_id);
                                        }}
                                      >
                                        <i className="bi bi-pencil-fill"></i>
                                        Update Status
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" className="no-data-found">
                                  ---- No Data Found ----
                                </td>
                              </tr>
                            )}
                          </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                    <div>
                      <button
                        className="submit-invoice btn-select-search pr-order btn btn-outline-primary float-right porder-button"
                        onClick={sendMultipleLineItems}
                      >
                        Submit Invoice
                      </button>
                    </div>

                    <ApproveInvoiceModel
        aproveStatus={aproveStatus}
        handleClose={handleClose}
        vhdId={vhdId}
        uId={uId}
        invSaveId={invSaveId}
        fetchinvoicedata={fetchInvoiceData}
      />
      <AlertMessageModel
        modelShow={modelShow}
        alertMessage={submitMessage}
        submitSuccessMessage={submitSuccessMessage}
        responseStatus={responseStatus}
        handleAlertClose={handleAlertClose}
      />

                  </div>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>

  );
}

export default withRouter(ApprovelmatrixDetails);
