// Import necessary dependencies
import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import Login from "./layouts/Login";

let userType = sessionStorage.getItem("loginType");
let vendorCode = sessionStorage.getItem("login-code");
let empId = sessionStorage.getItem("login-code");


// useEffect(() => {
//     setUserId(sessionStorage.getItem("email"));
//     setUserType(sessionStorage.getItem("loginType"));
//   }, [sessionStorage.getItem("loginType")]);

console.log(userType, "")

const App = () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    {userType ?
    <>
      {/* Route for the login page */}
      <Route
        path="/"
        exact
        component={withTracker(props => <Login {...props} />)}
      />
      <Route
        path="/login"
        exact
        component={withTracker(props => <Login {...props} />)}
      />

      {/* Other routes from your routes configuration */}
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={withTracker(props => (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          ))}
        />
      ))}
    </>
    :
    <>
    <Route
        path="*"
        exact
        component={withTracker(props => <Login {...props} />)}
      />
    </>}
  </Router>
);

export default App;
