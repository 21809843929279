import React from "react";
import { Redirect } from "react-router-dom";
// Layout Types
import { DefaultLayout } from "./layouts";
// Route Views
import Dashboard from "./views/Dashboard";
import InvoiceStatus from "./views/InvoiceStatus";
import Branch from "./views/Branch";
import PaymentStatus from "./views/PaymentStatus";
import Vendors from "./views/Vendors";
import PurchaseOrders from "./views/PurchaseOrders";
import EmployeeMaster from "./views/EmployeeMaster";
import Purchaseordersdetails from "./Purchaseorders-details";
import Paymentstatusdetails from "./Paymentstatus-details";
import Vendorlogindetails from "./Vendorlogin-details";
import Approvelmatrix from "./views/Approvelmatrix";
import BlogOverview from "./views/BlogOverview";
import ApprovelmatrixDetails from "./views/ApprovelmatrixDetails";

const routes = [

  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: Dashboard
  },

  {
    path: "/invoice-status",
    layout: DefaultLayout,
    exact: true,
    component: InvoiceStatus
  },
  {
    path: "/invoice-status/:vendorid",
    exact: true,
    layout: DefaultLayout,
    component: InvoiceStatus
  },
  {
    path: "/branch",
    exact: true,
    layout: DefaultLayout,
    component: Branch
  },
  {
    path: "/payment-status",
    exact: true,
    layout: DefaultLayout,
    component: PaymentStatus
  },
  {
    path: "/payment-status/:vendorid",
    exact: true,
    layout: DefaultLayout,
    component: PaymentStatus
  },
  {
    path: "/vendors",
    exact: true,
    layout: DefaultLayout,
    component: Vendors
  },
  {
    path: "/vendors/:id",
    exact: true,
    layout: DefaultLayout,
    component: Vendors
  },
  {
    path: "/purchase-orders",
    exact: true,
    layout: DefaultLayout,
    component: PurchaseOrders
  },

  {
    path: "/purchase-orders/:vendorid",
    exact: true,
    layout: DefaultLayout,
    component: PurchaseOrders
  },
  {
    path: "/employee-master",
    exact: true,
    layout: DefaultLayout,
    component: EmployeeMaster
  },
  {
    path: "/purchaseorders-details",
    exact: true,
    layout: DefaultLayout,
    component: Purchaseordersdetails
  },
  {
    path: "/purchaseorders-details/:id",
    exact: true,
    layout: DefaultLayout,
    component: Purchaseordersdetails
  },

  {
    path: "/paymentstatus-details/:id",
    exact: true,
    layout: DefaultLayout,
    component: Paymentstatusdetails
  },
  {
    path: "/vendorlogin-details",
    layout: DefaultLayout,
    component: Vendorlogindetails
  },
  {
    path: "/approvel-matrix",
    exact: true,
    layout: DefaultLayout,
    component: Approvelmatrix
  },
  {
    // path: "/approvel-matrix-details/:id/:docId",
    path: "/approvel-matrix-details/:id",
    exact: true,
    layout: DefaultLayout,
    component: ApprovelmatrixDetails
  },
  {
    path: "/blogoverview",
    layout: DefaultLayout,
    component: BlogOverview
  }
];

export default routes;
