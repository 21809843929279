import React from "react";
import Modal from "react-bootstrap/Modal";


function AlertMessageModel(props) {
  // console.log("SUCCESS-MESSAGE::", props.submitSuccessMessage, props.modelShow);
  // console.log("FAILED-MESSAGE::", props.alertMessage, props.modelShow);

  return (
    <div>
      <Modal
        show={props.modelShow}
        onHide={props.handleAlertClose}
        className="main_model alert-message-model invoice-created-status"
      >
        <div className="alert-msg-box">
          <span
            className={
              props.responseStatus == 100 || props.responseStatus == "Created"
                ? "alert-status-msg success"
                : "alert-status-msg fails"
            }
          >
            {props.responseStatus == 100 ||
            props.responseStatus == "Created" ? (
              <>
                <span>
                  <i className="fa fa-file-text" />
                  Invoice {props.submitSuccessMessage} Successfully
                </span>
              </>
            ) : (
              <span>
                <i className="fa fa-exclamation-circle" />
                {props.alertMessage}
              </span>
            )}
          </span>
        </div>
      </Modal>
    </div>
  );
}

export default AlertMessageModel;
