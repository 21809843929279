// import React from "react";
// import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';


// export default class Notifications extends React.Component {

//  constructor(props) {
//     super(props);

//     this.state = {
//       visible: false,
//       isFullscreen: false // Ensure isFullscreen is declared in the state
//     };

//     this.toggleNotifications = this.toggleNotifications.bind(this);
//   }

//   toggleNotifications() {
//     this.setState((prevState) => ({
//       visible: !prevState.visible
//     }));
//   }

//   toggleFullscreen = () => {
//     const { isFullscreen } = this.state; // Ensure isFullscreen is accessed from the state
//     if (!document.fullscreenElement && !isFullscreen) {
//       document.documentElement.requestFullscreen();
//       this.setState({ isFullscreen: true });
//     } else {
//       if (document.exitFullscreen) {
//         document.exitFullscreen();
//         this.setState({ isFullscreen: false });
//       }
//     }
//   };

//   render() {
//     return (
//       <NavItem className="border-right dropdown notifications">
//          <button onClick={toggleFullscreen}>
//         <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
//       </button>
//         <NavLink
//           className="nav-link-icon text-center"
//           onClick={this.toggleNotifications}
//         >
//           <div className="nav-link-icon__wrapper">
//             <i className="material-icons">&#xE7F4;</i>
//             <Badge pill theme="danger">
//               2
//             </Badge>
//           </div>
//         </NavLink>
//         <Collapse
//           open={this.state.visible}
//           className="dropdown-menu dropdown-menu-small"
//         >

//         </Collapse>
//       </NavItem>
//     );
//   }
// }


import React from "react";
import { NavItem, NavLink, Badge, Collapse, DropdownItem } from "shards-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';

export default class Notifications extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      isFullscreen: false // Ensure isFullscreen is declared in the state
    };

    this.toggleNotifications = this.toggleNotifications.bind(this);
  }

  toggleNotifications() {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  }

  toggleFullscreen = () => {
    const { isFullscreen } = this.state; // Ensure isFullscreen is accessed from the state
    if (!document.fullscreenElement && !isFullscreen) {
      document.documentElement.requestFullscreen();
      this.setState({ isFullscreen: true });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        this.setState({ isFullscreen: false });
      }
    }
  };

  render() {
    const { isFullscreen } = this.state; // Access isFullscreen from state
    return (
      <NavItem className="border-right dropdown notifications">
        <button onClick={this.toggleFullscreen} className="fl-scren">
          <FontAwesomeIcon icon={isFullscreen ? faCompress : faExpand} />
        </button>
        <NavLink
          className="nav-link-icon text-center"
          onClick={this.toggleNotifications}
        >
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">&#xE7F4;</i>
            <Badge pill theme="danger">
              2
            </Badge>
          </div>
        </NavLink>
        <Collapse
          open={this.state.visible} // Use this.state.visible to determine if the collapse should be open
          className="dropdown-menu dropdown-menu-small"
        >
          {/* Add content inside the Collapse component if needed */}
        </Collapse>
      </NavItem>
    );
  }
}
