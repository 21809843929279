import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import PageTitle from "../components/common/PageTitle";
import Modal from "react-bootstrap/Modal";
import { branchmode } from "../views/BranchMode";
import ReactPaginate from "react-paginate";


const EmployeeMaster = () => {

  const deleteEmployee = () => {
    // Implement your delete logic here
    // You might want to make an API call or update the state to reflect the deletion
    // For now, let's just log a message
    console.log(`Deleting employee with code: ${empCode}`);
    // Assuming setDeleteSuccessRes is a function to update the success message
    setDeleteSuccessRes('Employee deleted successfully');
    // Close the modal
    handleAlertClose();
  };


  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertModelShow, setAlertModelShow] = useState(false);
  const [empCode, setEmpCode] = useState("");
  const [role, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [deleteSuccessRes, setDeleteSuccessRes] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [branch, setBranch] = useState("");
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [pageCount, setPageCount] = useState("");

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [data, setData] = useState('');

  const handleClearData = () => {
    setSelectedDepartment('');
    setSelectedRole('');
    setBranch('');
  };

const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  // If the search query is empty, display all data
  if (query.trim() === '') {
    setFilteredData(employee);
  } else {
    // Filter the data based on vendor_name or vendor_code
    const filtered = employee.filter(
      (item) =>
        item.emp_name.toLowerCase().includes(query.toLowerCase()) ||
        item.pk_emp_code.toString().includes(query)
    );
    setFilteredData(filtered);
  }
};

   const setSelectedRoleData = (value) => {
    console.log(value)
    setSelectedRole(value);

   }
   const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const PageSize = 20;

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

  const handleAlertClose = () => {
    setAlertModelShow(!alertModelShow);
    setEmpCode("");
    setDeleteSuccessRes("");
  };


  useEffect(() => {
    if(selectedRole || selectedDepartment || branch || status){
fetchFilterData(currentPage, PageSize)
    } else {
      fetchData();
    }
  }, [selectedRole, selectedDepartment, branch, status]);

  const fetchData = () => {
    let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
      fetch(
        `https://portea.betaphase.in/employee_masterjson/pages?page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )

      .then((res) => res.json())
        .then((res) => {
                console.log("BRANCHPODATA:", res);
                setEmployee(res.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));

        })
        .catch((error) => {
          console.log("ERROR :", error);

        });

    };

  const fetchFilterData = () => {

    let requestOption1 = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
      fetch(
        `https://portea.betaphase.in/employee_masterjson/Employee/search_dropdown_filter?role=${selectedRole}&dept=${selectedDepartment}&fk_branch=${branch}&status=${status}`,
        requestOption1
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res, "resres")
                // const data = res;
          setEmployee(res);
          setLoading(false);
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || 'An error occurred');
          setLoading(false);
        });
  };

useEffect(() => {
    fetchstatusData();
  }, []);

  const fetchstatusData = () => {
    fetch('https://portea.betaphase.in/employee_masterjson/get_distinct_status', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Roles data:', data);
        setStatusDropdown(data);
        setLoading(false);
      })

      .catch(error => {
        console.error('Error fetching roles:', error);
        setLoading(false);
        // Handle error state or display an error message to the user
      });
  };

  useEffect(() => {
    fetchRolesData();
  }, []);

  const fetchRolesData = () => {
    fetch('https://portea.betaphase.in/employee_masterjson/get_distinct_role', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Roles data:', data);
        setRoles(data);
        setLoading(false);
      })

      .catch(error => {
        console.error('Error fetching roles:', error);
        setLoading(false);
        // Handle error state or display an error message to the user
      });
  };

  useEffect(() => {
    deptData();
  }, []);

  const deptData = () => {
    fetch('https://portea.betaphase.in/employee_masterjson/get_distinct_dept', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Department data:', data);
        setDepartment(data);
        setLoading(false);
      })

      .catch(error => {
        console.error('Error fetching departments:', error);
        setLoading(false);
        // Handle error state or display an error message to the user
      });
  };

  useEffect(() => {
    statusData();
  }, []);

  const statusData = () => {
    fetch('https://portea.betaphase.in/employee_masterjson/get_distinct_status', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Department data:', data);
        setStatusDropdown(data);
        setLoading(false);
      })

      .catch(error => {
        console.error('Error fetching departments:', error);
        setLoading(false);
        // Handle error state or display an error message to the user
      });
  };
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Card className="mt-3">
        <CardBody className="emp-mstr">
          <Row noGutters className="page-header pb-3">
                <PageTitle sm="4" title="Employee Master" subtitle="" className="text-sm-left" />
             </Row>
             <Row>
                 <Col xs="12" sm="2" md="2">
                     <div className="inf-cnt mb-4">
                       <div className="w-100">

                        <select
                          className="form-control search-dash-summary"
                          value={selectedRole}
                          onChange={(e) => setSelectedRoleData(e.target.value)}
                        >
                          {loading ? (
                            <option value="0">Loading...</option>
                          ) : (
                            <>
                              <option value="">Role</option>
                              {role.map(role => (
                                <option key={role} value={role}>
                                  {role}
                                </option>
                              ))}
                            </>
                          )}
                        </select>

                       </div>
                     </div>
                 </Col>
                 <Col xs="12" sm="2" md="2">
                    <div className="inf-cnt ">
                      <div className="w-100">
                        <select
                          className="form-control search-dash-summary"
                          value={selectedDepartment}
                          onChange={(e) => setSelectedDepartment(e.target.value)}
                        >
                          {loading ? (
                            <option value="0">Loading...</option>
                          ) : (
                            <>
                              <option value="">Department</option>
                              {department.map(department => (
                                <option key={department} value={department}>
                                  {department}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                       </div>
                     </div>
                 </Col>
                 <Col xs="12" sm="2" md="2">
                    <div className="inf-cnt ">
                      <div className="w-100">
                       <select
                        aria-label="Default select example"
                        name="branch"
                        className="form-control select"
                        value={branch}
                        onChange={(event) => {
                          setBranch(event.target.value);
                        }}
                      >
                        <option className="option-label">Branch</option>
                        {branchmode.map((data, i) => (
                          <option key={i} value={data.code}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                     </div>
                  </div>
                </Col>
               <Col xs="12" sm="2" md="2">
                  <div className="inf-cnt">

                    <select
                        aria-label="Default select example"
                        name="status"
                        className="form-control select"
                        value={status}
                        onChange={(event) => {
                          setStatus(event.target.value);
                        }}
                      >
                        <option className="option-label">Status</option>
                        <option className="inactive">Inactive</option>
                        {statusDropdown.map((data, i) => (
                          <option key={i}>{data}</option>
                        ))}
                      </select>
                  </div>

               </Col>

                <Col xs="12" sm="1" md="1" style={{visibility:"hidden"}}>
                  <div className="inf-cnt btn-srch">

                      <div className="inf-cnt example">

                      <input type="text" placeholder="Search.." name="search2" />
                      <button type="submit"><i class="fa fa-search"></i></button>
                      </div>
                  </div>
                </Col>
                  <Col xs="12" sm="3" md="3">
                  <button onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", padding:"0.4375rem 0.75rem", borderRadius:"0.25rem", float:"right"}}>
                    <span class="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", paddingBottom:"5px", marginRight:"5px"}}>clear</span>Clear
                  </button>
                </Col>


          </Row>
          <div className="emp-tble">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <table className="table employee-table">
                <thead className="employee-head">
                          <tr>
                            <th className="tab-head">Employee Code</th>
                            <th className="tab-head">Employee</th>
                            <th className="tab-head">Role</th>
                            <th className="tab-head">Department</th>
                            <th className="tab-head">Mobile No</th>
                            <th className="tab-head">Branch</th>
                            <th className="tab-head">Email</th>
                            <th className="tab-head">Status </th>
                            <th className="tab-head">Action </th>
                          </tr>
                        </thead>
                <tbody className="employee-body">
                  {employee.length > 0 ? (
                    employee.map((item, i) => (
                      <tr key={i}>
                        <td className="tab-body">{item?.pk_emp_code}</td>
                        <td className="tab-body">{item?.emp_name}</td>
                        <td className="tab-body">{item?.role}</td>
                        <td className="tab-body">{item?.dept}</td>
                        <td className="tab-body">{item?.mobile_no}</td>
                        <td className="tab-body">{item?.branch_name}</td>
                        <td
                                  className="tab-body"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {item?.email}
                                </td>
                                {item?.status === "Active" ? (
                                  <td className="tab-body">
                                    <div className='d-flex align-items-center'>
                                    <p className="dot-ac"></p>
                                    {item?.status}
                                    </div>
                                  </td>
                                ) : (
                                  <td className="tab-body ina">
                                    <span className="dot-in"></span>
                                    {item?.status}
                                  </td>
                                )}
                                <td className="tab-body">
                                  <button
                                    className="btn-delete"
                                    title="Delete"
                                    onClick={() => {
                                      setAlertModelShow(!alertModelShow);
                                      setEmpCode(item?.pk_emp_code);
                                    }}
                                  >
                                    <i className="material-icons">delete</i>
                                  </button>
                                </td>
                        {/* Include your table data here */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="no-data-found">
                        ---- No Data Found ----
                      </td>
                    </tr>
                  )}
                  <>
                    <Modal
                      show={alertModelShow}
                      onHide={handleAlertClose}
                      className="main_model alert-message-model"
                    >
                      <div className="alert-msg-box-delete">
                        <div className="delete-employee-title">
                          <span className="model-title-delete d-flex">
                            <h6 className="delete-head-text">Delete Employee</h6>
                            <i className="bi bi-trash-fill" />
                          </span>
                          <i className="fa fa-times" onClick={handleAlertClose} />
                        </div>
                        <div className="alert-status-msg-delete">
                          {deleteSuccessRes ? (
                            <p className="delete-success-msg"> {deleteSuccessRes} </p>
                          ) : (
                            <p> Are you sure to delete this Employee? </p>
                          )}
                        </div>
                        <div className="delete-ok-cancel">
                          <button
                            className="btn btn-ok"
                            onClick={() => deleteEmployee(empCode)}
                          >
                            Delete
                          </button>
                          <button
                            className="btn btn-cancel-model"
                            onClick={handleAlertClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </>
                </tbody>
              </table>
            )}
          </div>
        </CardBody>
        <div className="porder-down d-flex">
                        <span className="page-range">
                          <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                          &nbsp; of &nbsp;
                          <p>{totalemployee}</p>
                        </span>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={<i className="fa fa-angle-double-left" />}
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />

            </div>
      </Card>
    </Container>
  );
};

export default EmployeeMaster;


