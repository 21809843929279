import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import {
  Card,
  CardBody,
} from "shards-react";
import ReactPaginate from "react-paginate";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Branch = () => {
 useEffect(() => {
   // Calculate the date from 30 days ago
    const thirtyDaysAgo = new Date(new Date());
    thirtyDaysAgo.setDate(new Date().getDate() - 400);

    // Set the state with the calculated date
    setFromDate(thirtyDaysAgo);
  }, []);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const [pageCount, setPageCount] = useState("");
  const [totalemployee, setTotalemployee] = useState("");
  const [fromDate, setFromDate] = useState('');
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);

  const [employee, setEmployee] = useState([]);
  const [branchdata, setBranchdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchval, setSearchval] = useState("");
  const [filteredData, setFilteredData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  const handleClearData = () => {
    setSearchval('');
    setFilteredData(branchdata);
  };


  const PageSize = 20;

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;

useEffect(() => {
    fetchbranchData();
  }, []);

  const fetchbranchData = () => {
    let requestOption = {
        method: "GET",
        mode: "cors",
         headers: {
                'Content-Type': 'application/json',
            },
      };
      fetch(
        `https://portea.betaphase.in/branchjson/pages?page_num=${currentPage}&page_size=${PageSize}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
                console.log("BRANCHPODATA:", res);
        setBranchdata(res.data);
        setPageDataSize(res.data.length);
        setTotalemployee(res.total);
        setPageCount(Math.ceil(res.total / PageSize));
        setEmployee(branchdata);
        setLoading(false);
        })
        .catch((error) => {
          console.log("ERROR :", error);
          setError(error.message || 'An error occurred');
      setLoading(false);
        });
  };

//   useEffect(() => {
//     setFilteredData(branchdata); // Set initial filtered data when component mounts
//    }, [branchdata]);
//   const serachData = (e) => {
//     const value = e.target.value;
//     setSearchval(value); // Update search value state

//     if (value) {
//         let filterData = branchdata.filter(item =>
//             // item.pk_vendor_code.toString().startsWith(value.toString()) ||
//             item.pk_branch_name.toLowerCase().includes(value.toLowerCase())
//         );
//         setFilteredData(filterData); // Update filtered data state
//     } else {
//         setFilteredData(branchdata); // Reset filtered data to original state
//     }
// }

useEffect(() => {
        setFilteredData(branchdata); // Set initial filtered data when component mounts
    }, [branchdata]);

    const serachData = () => {
        if (searchval) {
            let filteredData = branchdata.filter(item =>
                item.pk_branch_name.toLowerCase().includes(searchval.toLowerCase())
            );
            setFilteredData(filteredData); // Update filtered data state
        } else {
            setFilteredData(branchdata); // Reset filtered data to original state
        }
    };



// DOWNLOAD EXCEL-FORMATE START
  const fileName = "Branch-Details";
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const Heading = [
    {
      pk_branch_name: "Branch Name",
      branch_code: "Branch Code",
      purchase_order: "PO",
      invoice_status: "INV",
      payment_status: "PP",
      warehouse_city: "City",
      warehouse_address: "Location",
      warehouse_state: "Mobile Number",
    },
  ];

  const exportExcel = () => {
    const newBranchData = branchdata.map((data) => {
      delete data.warehouse_country;
      return data;
    });

    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: [
        "pk_branch_name",
        "branch_code",
        "purchase_order",
        "invoice_status",
        "payment_status",
        "warehouse_city",
        "warehouse_address",
        "warehouse_state",
      ],
      skipHeader: true,
      origin: 0,
    });
    XLSX.utils.sheet_add_json(ws, branchdata, {
      header: [
        "pk_branch_name",
        "branch_code",
        "purchase_order",
        "invoice_status",
        "payment_status",
        "warehouse_city",
        "warehouse_address",
        "warehouse_state",
      ],
      skipHeader: true,
      origin: -1,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  // DOWNLOAD EXCEL-FORMATE END
return (
  <Container fluid className="main-content-container px-4 pb-4">
    <Row>
    <Col xs="12" sm="12" md="12">
          <Card className="mt-3">
            <CardBody className="brnch">
              <Row noGutters className="page-header pb-3">
                <PageTitle sm="4" title="Branch" subtitle="" className="text-sm-left" />
              </Row>
              <Row>


                <Col xs="12" sm="3" md="3">
                  <div className="inf-cnt example">

                    <input type="text" placeholder="Search Branch" name="search2"  value={searchval}
                onChange={(e) => setSearchval(e.target.value)}/>
                    <button type="submit" onClick={serachData}><i class="fa fa-search"></i></button>
                  </div>
                </Col>
                <Col xs="12" sm="3" md="3" style={{visibility:"hidden"}}>
                  <div className="inf-cnt mb-4">
                    <div className="w-100">
                      <select className="form-control search-dash-summary">
                        <option value="0">Branch Name</option>
                        <option value="1">Bangalore</option>
                        <option value="2">Kerala</option>
                        <option value="3">Hyderabad</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="2" md="2" style={{visibility:"hidden"}}>
                  <div className="inf-cnt mb-4">
                    <div className="w-100">
                      <select className="form-control search-dash-summary">
                        <option value="0">Branch Name</option>
                        <option value="1">Bangalore</option>
                        <option value="2">Kerala</option>
                        <option value="3">Hyderabad</option>
                      </select>
                    </div>
                  </div>
                </Col>

                <Col xs="12" sm="4" md="4">
                  <button type="button" className="pr-order btn btn-outline-primary float-right porder-button" onClick={(e) => exportExcel(branchdata)} style={{marginRight:"0px"}}>
                      Download &nbsp;
                      <span class="material-icons">download</span>
                  </button>
                  <button className="pr-order btn btn-outline-primary porder-button brnch-btn" onClick={handleClearData} style={{background:"#00979e", border:"none", color:"#ffffff", display:"flex", alignItems:"center", borderRadius:"0.25rem", marginRight:"15px"}}>
                    <span class="material-icons" style={{background:"#00979e", borderRadius:"50", color:"#ffffff", fontSize:"16px", marginRight:"5px"}}>clear</span>Clear
                  </button>

                </Col>
              </Row>
              <div className="emp-tble">
                {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <table className="table employee-table">
                <thead className="employee-head">
                          <tr>
                            <th className="tab-head">Branch Code</th>
                            <th className="tab-head">Branch</th>
                            <th className="tab-head">PO</th>
                            <th className="tab-head">INV</th>
                            <th className="tab-head">PP</th>
                            <th className="tab-head">City</th>
                            <th className="tab-head">Location</th>

                          </tr>
                        </thead>
                    <tbody>
                    {filteredData.length !== 0 ? (
                          filteredData.map((item, i) => (

                                <tr key={i}>
                                  <td className="branch-tbody">
                                    {item?.branch_code}
                                  </td>
                                  <td className="branch-tbody">
                                    {item?.pk_branch_name}
                                  </td>
                                  <td className="branch-po-tbody">
                                    <Link
                                      to="/purchase-orders"
                                      className="branch-po"
                                    >
                                      {item?.purchase_order}
                                    </Link>
                                  </td>
                                  <td className="branch-po-tbody">
                                    <Link
                                      to="/invoice-status"
                                      className="branch-inv"
                                    >
                                      {item?.invoice_status}
                                    </Link>
                                  </td>
                                  <td className="branch-po-tbody">
                                    <Link
                                      to="/payment-status"
                                      className="branch-pp"
                                    >
                                      {item?.payment_status}
                                    </Link>
                                  </td>
                                  <td
                                    className="branch-tbody"
                                    style={{maxWidth:"200px"}}
                                  >
                                    {item?.warehouse_city}
                                  </td>
                                  {/* <td className="branch-tbody">{item?.state}</td> */}
                                  <td
                                    className="branch-tbody"

                                  >
                                    {item?.warehouse_address}
                                  </td>
                                  {/* <td className="branch-tbody">Email</td>
                                <td className="branch-tbody">Mobiile Num</td> */}
                                </tr>
                              ))
                          ) : (
                            <span className="no-data-found">
                              ---- No Data Found ----
                            </span>
                          )}
                        </tbody>
              </table>
            )}

              </div>


        </CardBody>



              <div className="porder-down d-flex">
                          <span className="page-range">
                            <p>{pageDataFrom}</p>-<p>{pageDataTill}</p>
                            &nbsp; of &nbsp;
                            <p>{totalemployee}</p>
                          </span>
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-double-right" />}
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel={<i className="fa fa-angle-double-left" />}
                            renderOnZeroPageCount={null}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                          />

              </div>


          </Card>
        </Col>
    </Row>
  </Container>

);
};

export default Branch;
