
import React, {useEffect, useState, useRef } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "shards-react";
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import SmallStats from "./../components/common/SmallStats";
// import Chart from 'chart.js';
import Chart from "react-apexcharts";
import "../assets/css/my.css";
import poimg from "./../images/po.png";
import invimg from "./../images/inv.png";
import ppimg from "./../images/pp.png";
import PageTitle from "../components/common/PageTitle";
import ReactApexChart from 'react-apexcharts';
import PropTypes from "prop-types";
import axios from 'axios';
// import { getColor } from '../utils/colors';
// import { randomNum } from '../utils/demos';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  AreaChart,
  Area,
} from "recharts";
import { MeetingsChart } from "./dbrd";

import { Line, ComposedChart } from 'recharts';



const Dashboard = ({ smallStats, history}) => {

  const [vendordata, setVendordata] = useState([]);

  const userType = sessionStorage.getItem("loginType");
  const vendorCode = sessionStorage.getItem("login-code");

  const [selectedOption, setSelectedOption] = useState('ALL');
  const typeOfview = "vendorInv";
  const [recentPp, setRecentPP] = useState([]);
  const [recentInv, setRecentINV] = useState([]);
  const [recentPo, setRecentPO] = useState([]);
  const [recentActivityId, setRecentActivityId] = useState("");
  const [searchitem, setSearchitem] = useState("");

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState('');
  // const [originalData, setOriginalData] = useState([]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // If the search query is empty, display all data
    if (query.trim() === '') {
      setFilteredData(vendordata);
    } else {
      // Filter the data based on vendor_name or vendor_code
      const filtered = vendordata.filter(
        (vendordata) =>
          vendordata.vendor_name.toLowerCase().includes(query.toLowerCase()) ||
          vendordata.pk_vendor_code.toString().includes(query)
      );

      setFilteredData(filtered);
    }
  };
    useEffect(() => {

    setRecentPO([/* PO data */]);
    setRecentINV([/* Invoice data */]);
    setRecentPP([/* Payment data */]);
}, []);

   const [graphData, setGraphData] = useState([
    {
      label: "Active Vendors",
      value: "",
      // percentage: false,
      // increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "PO Order",
      value: "",
      // percentage: "12.4",
      // increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Invoice Created",
      value: "",
      // percentage: "3.8%",
      // increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "Payment Paid",
      value: "",
      // percentage: "2.71%",
      // increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },

  ])

  const [tableData, setTableData] = useState([]);
useEffect(() => {
  fetchCardsData();
}, []);

const fetchCardsData = () => {
  let requestOption = {
    method: "GET",
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (userType === "Vendor") {
    fetch(
      `https://portea.betaphase.in/dashboard/get_data_widgets/vendors/?vendor_code=${vendorCode}`,
      requestOption
    )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((res) => {
      console.log("Vendor-specific data:", res); // Log the response to check the data
      // Exclude "Active Vendors" data if the user is a vendor
      const filteredGraphData = graphData.filter(item => item.label !== "Active Vendors");
      console.log("Filtered graph data:", filteredGraphData); // Log the filtered graph data
      setGraphData(filteredGraphData);
      // Set vendor login data
      let updatedGraphData = filteredGraphData.map((item) => {
        if (item.label === "PO Order") {
          return { ...item, value: 4 }; // Set PO Order to 4 for vendor login
        } else if (item.label === "Invoice Created") {
          return { ...item, value: 1 };
        } else if (item.label === "Payment Paid") {
          return { ...item, value: 1 };
        } else {
          return item;
        }
      });
      console.log("Updated graph data:", updatedGraphData); // Log the updated graph data
      setGraphData(updatedGraphData);
    })
    .catch((error) => {
      console.error("FETCH ERROR:", error);
    });
  } else {
    fetch(
      `https://portea.betaphase.in/dashboard/get_data_widgets`,
      requestOption
    )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      return res.json();
    })
    .then((res) => {
      console.log("General data:", res); // Log the response to check the data
      // Update other data normally
      let updatedGraphData = graphData.map((item) => {
        if (item.label === "Active Vendors") {
          return { ...item, value: res.active_vendors };
        } else if (item.label === "PO Order") {
          return { ...item, value: res.po_sent };
        } else if (item.label === "Invoice Created") {
          return { ...item, value: res.invoice_save };
        } else if (item.label === "Payment Paid") {
          return { ...item, value: res.payments_paid };
        } else {
          return item;
        }
      });
      console.log("Updated graph data:", updatedGraphData); // Log the updated graph data
      setGraphData(updatedGraphData);
    })
    .catch((error) => {
      console.error("FETCH ERROR:", error);
    });
  }
};


// const fetchCardsData = () => {
//   let requestOption = {
//     method: "GET",
//     mode: "cors",
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   };

//   if (userType === "vendor") {
//     fetch(

//       `https://portea.betaphase.in/dashboard/get_data_widgets/vendors/?vendor_code=${vendorCode}`,
//       requestOption
//     )
//     .then((res) => res.json())
//     .then((res) => {
//       // Exclude "Active Vendors" data if the user is a vendor
//       const filteredGraphData = graphData.filter(item => item.label !== "Active Vendors");
//       setGraphData(filteredGraphData);
//     })
//     .catch((error) => {
//       console.error("FETCH ERROR:", error);
//     });
//   } else {
//     fetch(
//       `https://portea.betaphase.in/dashboard/get_data_widgets`,
//       requestOption
//     )
//     .then((res) => {
//       if (!res.ok) {
//         throw new Error(`HTTP error! Status: ${res.status}`);
//       }
//       return res.json();
//     })
//     .then((res) => {
//       // Update other data normally
//       let updatedGraphData = graphData.map((item) => {
//         if (item.label === "Active Vendors") {
//           return { ...item, value: res.active_vendors };
//         } else if (item.label === "PO Order") {
//           return { ...item, value: res.po_sent };
//         } else if (item.label === "Invoice Created") {
//           return { ...item, value: res.invoice_save };
//         } else if (item.label === "Payment Paid") {
//           return { ...item, value: res.payments_paid };
//         } else {
//           return item;
//         }
//       });
//       setGraphData(updatedGraphData);
//     })
//     .catch((error) => {
//       console.error("FETCH ERROR:", error);
//     });
//   }
// };

    // Assuming you want to use graphData somewhere else
smallStats = graphData;

    useEffect(() => {
      fetchrecentactivitiesData()
      }, []);

    const fetchrecentactivitiesData = () => {
      let requestOption = {
          method: "GET",
          mode: "cors",
           headers: {
                  'Content-Type': 'application/json',
              },
        };
        fetch(
          `https://portea.betaphase.in/purchase_orderjson/dashboard_get_data/recent_activity_po`,
          requestOption
        )
          .then((res) => res.json())
          .then((res) => {
            if (recentActivityId == "") {
              setRecentPO(res.slice(0, 3));
            } else if (recentActivityId === "PO") {
              setRecentPO(res);
            } else {
              setRecentPO([]);
            }
          });

        }
  useEffect(() => {
      fetchrecentactivities1Data()
      }, []);

    const fetchrecentactivities1Data = () => {
      let requestOption = {
          method: "GET",
          mode: "cors",
           headers: {
                  'Content-Type': 'application/json',
              },
        };

        fetch(
      `https://portea.betaphase.in/invoice_statusjson/dashboard_get_data/recent_activity_invoice_status`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (recentActivityId == "") {
          setRecentINV(res.slice(0, 3));
        } else if (recentActivityId === "INV") {
          setRecentINV(res);
        } else {
          setRecentINV([]);
        }
      });
    }
    useEffect(() => {
      fetchrecentactivities2Data()
      }, []);

    const fetchrecentactivities2Data = () => {
      let requestOption = {
          method: "GET",
          mode: "cors",
           headers: {
                  'Content-Type': 'application/json',
              },
        };
    fetch(
      `https://portea.betaphase.in/payment_statusjson/dashboard_get_data/recent_activity_pp`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("PP-RES:", res);
        if (recentActivityId === "") {
          setRecentPP(res.slice(0, 3));
        } else if (recentActivityId === "PP") {
          setRecentPP(res);
        } else {
          setRecentPP([]);
        }
      });
    }
    const [chartVisible, setChartVisible] = useState(false);
    const chartRef = useRef(null);
    useEffect(() => {
    fetchsummaryData()
 }, []);

    const fetchsummaryData = () => {
     let requestOption = {
          method: "GET",
          mode: "cors",
           headers: {
                  'Content-Type': 'application/json',
              },
        };
    if (userType === "Vendor") {
      fetch(
        `https://portea.betaphase.in/vendorjson/po_data/inv_data/pp_data/based_on_vendor/individual_vendor?pk_vendor_code=${vendorCode}`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("DB-VENDOR-Login-RES:", res);
          setVendordata(res);
        });
    } else {
      fetch(
        `https://portea.betaphase.in/vendorjson/po_data/inv_data/pp_data/based_on_vendors/multiple_vendors/`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("DASH-VENDOR-RESPONSE:", res);
          setVendordata(res);
        });
    }
  };

  // const data = [
  //   { name: 'Purchase Orders', bar: 4, line: 4 },
  //   { name: 'Invoice Orders', bar: 1, line: 1 },
  //   { name: 'Payment Status', bar: 1, line: 1 },

  // ];

//   const data = [
//     { name: 'Purchase Orders', bar: 4, line: 4 },
//     { name: 'Invoice Created', bar: 1, line: 1 },
//     { name: 'Payment Paid', bar: 1, line: 1 },
// ];

// const [chartData, setChartData] = useState({
//   series: [
//     {
//       name: '',
//       type: 'column',
//       data: [4, 1, 1]
//     },
//     {
//       name: '',
//       type: 'line',
//       data: [4, 1, 1]
//     }
//   ],
//   options: {
//     chart: {
//       height: 350,
//       type: 'line'
//     },
//     stroke: {
//       width: [0, 4]
//     },

//     dataLabels: {
//       enabled: true,
//       enabledOnSeries: [1]
//     },
//     labels: [
//       'Purchase Orders', 'Invoice Created', 'Payment Paid'
//     ],
//     // xaxis: {
//     //   type: 'datetime'
//     // },
//     yaxis: [
//       {
//         title: {
//           text: ''
//         }
//       },
//       {
//         opposite: true,
//         title: {
//           text: ''
//         }
//       }
//     ]
//   }
// });

// const [chartData, setChartData] = useState({
//   series: [
//     {
//       name: '',
//       type: 'column',
//       data: [4, 1, 1]
//     },
//     {
//       name: '',
//       type: 'line',
//       data: [4, 1, 1]
//     }
//   ],
//   options: {
//     chart: {
//       height: 350,
//       type: 'line'
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: '10%', // Adjust the width of the columns here
//       }
//     },
//     stroke: {
//       width: [0, 4]
//     },
//     dataLabels: {
//       enabled: true,
//       enabledOnSeries: [1]
//     },
//     labels: [
//       'Purchase Orders', 'Invoice Created', 'Payment Paid'
//     ],
//     // xaxis: {
//     //   type: 'datetime'
//     // },
//     yaxis: [
//       {
//         title: {
//           text: ''
//         }
//       },
//       {
//         opposite: true,
//         title: {
//           text: ''
//         }
//       }
//     ]
//   }
// });

const [chartData, setChartData] = useState({
  series: [
    {
      name: '',
      type: 'column',
      data: [4, 1, 1]
    },
    {
      name: '',
      type: 'line',
      data: [4, 1, 1]
    }
  ],
  options: {
    chart: {
      height: 350,
      type: 'line'
    },
    plotOptions: {
      bar: {
        columnWidth: '10%',
      }
    },
    colors: ['#00979e', '#fcaf56'], // Specify the colors for the column and line series
    stroke: {
      width: [0, 1]
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1]
    },
    labels: [
      'Purchase Orders', 'Invoice Created', 'Payment Paid'
    ],
    yaxis: [
      {
        title: {
          text: ''
        }
      },
      {
        opposite: true,
        title: {
          text: ''
        }
      }
    ],
    xaxis: {
      categories: ['Purchase Orders', 'Invoice Created', 'Payment Paid'],
      labels: {
        style: {
          fontSize: '12px',
          letterSpacing: '-1px'
        }
      }
    }
  }
});




return (
  <>
   {userType === "Admin" ||
      userType === "admin" ||
      userType === "Vendor" ||
      userType === "vendor" ||
      userType === "Approver" ||
      userType === "approver" ? (
        <Container fluid className="main-content-container px-4">
          <Card className="mt-3 mb-3">
            <CardBody>
              <Row noGutters className="page-header pb-3">
                <PageTitle title="Dashboard" subtitle="" className="text-left mb-3" />
              </Row>
              <Row>
                  {smallStats &&
                    smallStats.map((stats, idx) => {
                      // Check if the user is a vendor and the statistic is "Active Vendors"
                      if (userType === 'Vendor' && stats.label === 'Active Vendors') {
                        // Skip rendering this statistic for vendors
                        return null;
                      }

                      // Render the SmallStats component for other statistics or non-vendor users
                      return (
                        <Col className="col-lg mb-2" key={idx} {...stats.attrs}>
                          <SmallStats
                            id={`small-stats-${idx}`}
                            variation="1"
                            chartData={stats.datasets}
                            chartLabels={stats.chartLabels}
                            label={stats.label}
                            value={stats.value}
                            percentage={stats.percentage}
                            increase={stats.increase}
                            decrease={stats.decrease}
                          />
                        </Col>
                      );
                    })}
              </Row>


            </CardBody>
          </Card>

                        {userType === "Vendor" || userType === "vendor" ? (
                          <div className="row dash-card">
                            <div className="col-md-12">
                              <div className="vendor-bar-chart">
                                <h6 className="chart-heading text-center" style={{fontWeight:700}}>Vendor Graph Chart</h6>

                                {/* <ResponsiveContainer width="100%" aspect={4}>
                                  <AreaChart
                                    width={600}
                                    height={400}
                                    data={vendordata}
                                    margin={{
                                      top: 30,
                                      right: 20,
                                      left: 20,
                                      bottom: 0,
                                    }}
                                  >
                                    <CartesianGrid strokeDasharray="1 2" />
                                    <XAxis dataKey="vendor_name" />
                                    <YAxis
                                      label={{
                                        value: "Count",
                                        angle: -90,
                                        position: "insideLeft",
                                      }}
                                    />
                                    <Tooltip />
                                    <Legend
                                      verticalAlign="bottom"
                                      wrapperStyle={{ lineHeight: "20px" }}
                                    />
                                    <Area
                                      type="monotone"
                                      dataKey="purchase_order"
                                      fill="rgba(23,198,113,0.1)"
                                      opacity={1}
                                      name="Purchase Order"
                                      label={{
                                        value: "PO",
                                        position: "inside",
                                      }}
                                    />
                                    <Area
                                      type="monotone"
                                      dataKey="invoice_status"
                                      fill="rgba(255,180,0,0.1)"
                                      opacity={1}
                                      name="Invoice Status"
                                      label={{
                                        value: "INV",
                                        position: "inside",
                                      }}
                                    />
                                    <Area
                                      type="monotone"
                                      dataKey="payment_status"
                                      fill="rgba(255,65,105,0.1)"
                                      opacity={1}
                                      name="Payment Status"
                                      label={{
                                        value: "PP",
                                        position: "inside",
                                      }}
                                    />
                                  </AreaChart>
                                </ResponsiveContainer> */}
                                {/* <MeetingsChart
                                        width={"100%"}
                                        // style={{overflowX: 'auto'}}
                                      /> */}
                                {/* <Chart options={chartData.options} series={chartData.series} type="area" width="100%" height={500} /> */}
                                {/* <ResponsiveContainer width="90%" aspect={2}>
                                  <BarChart
                                    width={200}
                                    height={350}
                                    data={vendordata}
                                    margin={{
                                      top: 30,
                                      right: 20,
                                      left: 20,
                                      bottom: 0,
                                    }}
                                    barSize={60}
                                  >
                                    <CartesianGrid strokeDasharray="1 2" />
                                    <XAxis
                                      dataKey="vendor_name"
                                      // padding={{ left: 20, right: 20 }}
                                    />
                                    <YAxis
                                      label={{
                                        value: "Count",
                                        angle: -90,
                                        position: "insideLeft",
                                      }}
                                    />
                                    <Tooltip />
                                    <Legend
                                      verticalAlign="bottom"
                                      wrapperStyle={{ lineHeight: "20px" }}
                                    />
                                    <Bar
                                      dataKey="purchase_order"
                                      fill="rgba(0, 184, 216, 0.1)"
                                      opacity={1}
                                      name="Purchase Order"
                                      label={{
                                        value: "PO",
                                        position: "inside",
                                      }}
                                      margin={{
                                        top: 0,
                                        right: 20,
                                        left: 0,
                                        bottom: 0,
                                      }}
                                    />
                                    <Bar
                                      dataKey="invoice_status"
                                      fill="#ef4770"
                                      opacity={0.6}
                                      name="Invoice Status"
                                      label={{
                                        value: "INV",
                                        position: "inside",
                                      }}
                                    />
                                    <Bar
                                      dataKey="payment_status"
                                      fill="#00979e"
                                      opacity={0.6}
                                      name="Payment Status"
                                      label={{
                                        value: "PP",
                                        position: "inside",
                                      }}
                                    />
                                  </BarChart>
                                </ResponsiveContainer> */}
                                {/* <ComposedChart
                                    width={800}
                                    height={400}
                                    data={data}
                                    margin={{
                                      top: 20, right: 30, left: 20, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid stroke="#f5f5f5" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="bar" barSize={20} fill="#00979e" />
                                    <Line type="monotone" dataKey="line" stroke="#ff7300" />
                                </ComposedChart> */}
                                {/* <ComposedChart
    width={1000} // Adjust width to 1000 for full width
    height={400}
    data={data}
    margin={{
        top: 20, right: 30, left: 20, bottom: 5,
    }}
>
    <CartesianGrid stroke="#f5f5f5" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar type="monotone" dataKey="bar" barSize={20} fill="#00979e" name="Purchase Orders" />

    <Line type="monotone" dataKey="invoice" stroke="#00979e" name="Invoice" />
    <Line type="monotone" dataKey="line" stroke="#00979e" name="Payment" />
</ComposedChart> */}

{/* <ComposedChart
        width={1000}
        height={400}
        data={data}
        margin={{
            top: 20, right: 30, left: 20, bottom: 5,
        }}
    >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />


        <Bar type="monotone" dataKey="bar" barSize={20} fill="#00979e" name="Purchase Orders" />


        <Line type="monotone" dataKey="line"  name="" />
    </ComposedChart> */}
<div className="graph-container" style={{width: "70%", margin:"0 auto"}}>
  <div className="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
  </div>
</div>

                              </div>
                            </div>
                            <div>
                          </div>
                          </div>
                        ) : (
                        <div className="mt-0 mb-2">
                          <Row>
                            <Col xs="12" sm="6" md="8">
                              <Card>
                                <CardBody className="crd-info">
                                  <div className="d-flex mb-3">
                                    <Col md="6">
                                      <span>Summary</span>
                                    </Col>
                                    <Col md="6">
                                      <div className="inf-cnt example">
                                        <input type="text" placeholder="Search.." name="search2"
                                          onChange={(e) =>setSearchitem(e.target.value)}
                                        />
                                        <button type="submit"><i class="fa fa-search"></i></button>
                                      </div>
                                    </Col>

                                  </div>
                                  <div className="actvty-tble">

                                    <div className="table-wrapper-scroll-x my-custom-scrollbar dash-scroll">
                                                    <table className="table dash-table">
                                                      <thead className="dash-head">
                                                        <tr className="data">
                                                          <th className="dash-thead">
                                                            Vendor Code
                                                          </th>
                                                          <th className="dash-thead">
                                                            Vendor Name
                                                          </th>
                                                          <th className="dash-thead">PO</th>
                                                          <th className="dash-thead">Invoice</th>
                                                          <th className="dash-thead">
                                                            Payments (₹)
                                                          </th>
                                                          <th className="dash-thead">Action</th>
                                                        </tr>
                                                      </thead>

                                                      <tbody>
                                                        {vendordata !== "" ||
                                                        vendordata !== null ? (
                                                          vendordata
                                                            .filter(
                                                              (data) =>
                                                                data?.vendor_name
                                                                  .toLowerCase()
                                                                  .includes(searchitem) ||
                                                                data?.pk_vendor_code.includes(
                                                                  searchitem
                                                                )
                                                            )
                                                            .map((vdata, i) => (
                                                              <tr key={i}>
                                                                <td className="dash-tbody">
                                                                  {vdata?.pk_vendor_code}
                                                                </td>
                                                                <td
                                                                  className="dash-tbody"
                                                                  style={{ maxWidth: "200px" }}
                                                                >
                                                                  {vdata?.vendor_name}
                                                                </td>
                                                                <td
                                                                  className="dash-tbody"

                                                                >
                                                                  <Link
                                                                    to={`/purchase-orders/${vdata?.pk_vendor_code}`}
                                                                    className="act-link"
                                                                  >
                                                                    {vdata?.purchase_order}
                                                                  </Link>
                                                                </td>
                                                                <td
                                                                  className="dash-tbody"

                                                                >
                                                                  <Link
                                                                    to={`/invoice-status/${vdata?.pk_vendor_code}`}
                                                                    className="act-link"
                                                                  >
                                                                    {vdata?.invoice_status}
                                                                  </Link>
                                                                </td>
                                                                <td
                                                                  className="dash-tbody"

                                                                >
                                                                  <Link
                                                                    to={`/payment-status/${vdata?.pk_vendor_code}`}
                                                                    className="act-link"
                                                                  >
                                                                    {vdata?.payment_status}
                                                                  </Link>
                                                                </td>
                                                                {/* <td className="dash-tbody act-link" onClick={(e) => handleButtonClick(vdata?.pk_vendor_code)}>

                                                                    View

                                                                </td> */}
                                                                <td className="dash-tbody">
                                                                  <Link
                                                                    to={`/vendors/${vdata?.pk_vendor_code}`}
                                                                    className="act-link"
                                                                  >
                                                                    View
                                                                  </Link>
                                                                </td>
                                                                {/* <Link
                                                                    to={`/vendor/${vdata?.pk_vendor_code}`}
                                                                    className="act-link"
                                                                  >
                                                                    View
                                                                  </Link> */}
                                                              </tr>
                                                            ))
                                                        ) : (
                                                          <span className="no-data-found">
                                                            ---- No Data Found ----
                                                          </span>
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>

                                  </div>

                                </CardBody>
                              </Card>
                            </Col>
                            <Col xs="12" sm="6" md="4">
                              <Card>
                                <CardBody className="crd-info">
                                  <div className="d-flex mb-3">
                                    <Col md="6">
                                      <span className="rct-cnt">Recent Activity</span>
                                    </Col>
                                    <Col md="6">
                                      <div className="w-100">
                                        <select className="form-control search-dash-summary" onChange={(event) => setSelectedOption(event.target.value)} value={selectedOption}>
                                          <option value="ALL">All</option>
                                          <option value="PO">Purchase Orders</option>
                                          <option value="INV">Invoice</option>
                                          <option value="PP">Payments</option>
                                        </select>
                                      </div>
                                    </Col>
                                  </div>
                                  <div className="actvty-crd">
                                    <div className="row mn-cnt">
                                                  {(selectedOption === "ALL" || selectedOption === "PO") && recentPo.map((data, i) => (
                                                        <div
                                                          className="card"
                                                          id="purchase-order"
                                                          key={i}
                                                        >
                                                          <div className="card-body vendor-card">
                                                            <div className="card-row-content d-flex">
                                                              <span className="pay">
                                                                <img
                                                                  src={poimg}
                                                                  alt="po"

                                                                />
                                                              </span>
                                                              <span className="card-rtext">
                                                                <p className="ven-text">
                                                                  Portea has raised PO &nbsp;
                                                                  <b>{data?.pk_po_DocEntry}</b>
                                                                  &nbsp; against &nbsp;
                                                                  <b className="icon-ven">
                                                                    {data?.vendor_name}
                                                                  </b>
                                                                  &nbsp; for a value of &nbsp;
                                                                  <b>Rs.{data?.doc_total}</b>
                                                                </p>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>

                                                    ))}

                                                  {(selectedOption === "ALL" || selectedOption === "INV") && recentInv.map((data, i) => (
                                                        <div
                                                          className="card"
                                                          id="invoice"
                                                          key={i}
                                                        >
                                                          <div className="card-body vendor-card">
                                                            <div className="card-row-content d-flex">
                                                              <span className="pay">
                                                                <img
                                                                  src={invimg}
                                                                  alt="inv"

                                                                />
                                                              </span>
                                                              <span className="card-rtext">
                                                                <p className="ven-text">
                                                                  <b className="icon-ven">
                                                                    {data?.vendor_name}
                                                                  </b>
                                                                  &nbsp; has created Invoice &nbsp;
                                                                  <b>{data?.pk_AP_DocEntry}</b>
                                                                  &nbsp; for a value of &nbsp;
                                                                  <b>Rs.{data?.payment_amount}</b>
                                                                  &nbsp; against the PO &nbsp;
                                                                  <b>{data?.fk_po_DocEntry}</b>
                                                                  &nbsp;raised by Portea
                                                                </p>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}

                                                  {(selectedOption === "ALL" || selectedOption === "PP") && recentPp.map((data, i) => (
                                                        <div
                                                          className="card"
                                                          id="payments"
                                                          key={i}
                                                        >
                                                          <div className="card-body vendor-card">
                                                            <div className="card-row-content d-flex">
                                                              <span className="pay">
                                                                <img
                                                                  src={ppimg}
                                                                  alt="pp"

                                                                />
                                                              </span>
                                                              <span className="card-rtext">
                                                                <p className="ven-text">
                                                                  Portea has made partial/full
                                                                  payment of &nbsp;
                                                                  <b>Rs.{data?.paid_amount}</b>
                                                                  &nbsp; against the Invoice Created
                                                                  by &nbsp;
                                                                  <b className="icon-ven">
                                                                    {data?.vendor_name}
                                                                  </b>
                                                                </p>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ))}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          <Row>

                          </Row>
                        </div>
                        )}
        </Container>
      ) : (
        < >
        </>
      )}
  </>
);

          }

export default Dashboard;

